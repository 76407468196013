import { ILayout, ILayoutPage } from 'Module/Layout/Interface';
import React from 'react';
import { IChangePassword, IChangePasswordContext, IChangePasswordPage, IChangePasswordResponse } from '../Interface';
import ChangePasswordPage from '../Page/index';
import SetChangePasswordService from '../Service/SetChangePassword/index';

function ChangePasswordController(props: ILayoutPage) {
  const [contextChangePassword] = React.useState<IChangePasswordContext>({ result: [] });
  const [showLoadingThisPage, setShowLoadingThisPage] = React.useState<boolean>(true);

  const toggleSetChangePassword = (field: IChangePassword) => {
    setShowLoadingThisPage(true);
    SetChangePasswordService(field, field.id)
      .then((response: IChangePasswordResponse) => {
        let layoutAlert: ILayout = {
          loading: false,
          typeMessage: 'success',
          titleMessage: 'Salvo',
          message: 'Registro alterado com sucesso',
        }
        props.toggleSetLayout(layoutAlert);
        setShowLoadingThisPage(false);
        if (props.toggleShowModalChangePassword) {
          props.toggleShowModalChangePassword(false)
        }
      })
      .catch((error: IChangePasswordResponse) => {
        console.log(error.message);
        let layoutAlert: ILayout = {
          loading: false,
          typeMessage: 'error',
          titleMessage: 'Erro ao alterar a senha',
          message: error.message,
        }
        props.toggleSetLayout(layoutAlert);
        setShowLoadingThisPage(false);
      })
  };



  const handlersPage: IChangePasswordPage = {
    ...props,
    context: contextChangePassword,
    showLoadingThisPage,
    toggleSetChangePassword
  };

  return (
    <React.Fragment>
      <ChangePasswordPage {...handlersPage} />
    </React.Fragment>
  );
}

export default ChangePasswordController;
