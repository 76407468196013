import { useRef, useEffect } from 'react';
import ReactSelect from 'react-select';
import { useField } from '@unform/core';
import { useStyles } from '../../../../Style/mui/formSelect';

import {
  Grid
  , Typography
  , Box
} from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTimesCircle,
  faPencilAlt
} from '@fortawesome/free-solid-svg-icons'

const customStyles = {
  option: (styles, state) => {
    return {
      ...styles,
      fontSize: '0.8rem',
      borderBottom: '1px dotted #D4D4D4',
      padding: '2px',
      backgroundColor: state.isDisabled
        ? '#E9E9E9'
        : state.isSelected
          ? '#B4E0E4'
          : state.isFocused
            ? '#F4F4F4'
            : null,
      color: state.isDisabled
        ? '#4d4d4d'
        : state.isSelected
          ? '#757575'
          : '#4d4d4d',
      cursor: state.isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor:
          !state.isDisabled && (state.isSelected ? '#4d4d4d' : '#F4F4F4'),
      },
    };
  },
  valueContainer: styles => ({
    ...styles
  }),
  indicatorsContainer: (styles, state) => ({
    ...styles,
    fontSize: '0.7rem',
    color: '#4d4d4d',
    height: '30px',
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
  }),
  control: (styles, state) => ({
    ...styles,
    padding: '0px',
    fontSize: '0.7rem',
    color: '#4d4d4d',
    border: '1px solid rgb(0, 56, 101)',
    borderRadius: '15px',
    backgroundColor: state.isDisabled ? '#E9E9E9' : '#FFFFFF',
    minHeight: '30px',
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 1 : 1;
    const transition = 'opacity 300ms';
    const background = state.isDisabled ? '#E9E9E9' : '#FFFFFF';
    return {
      ...provided,
      opacity,
      background,
      transition,
      fontSize: '0.7rem',
      color: '#4d4d4d',
      padding: '0.1rem',
      margin: 0,
    };
  },
  input: styles => ({
    ...styles,
    fontSize: '0.7rem',
    color: '#4d4d4d',
  }),
  placeholder: styles => ({
    ...styles,
    fontSize: '0.7rem',
    color: '#4d4d4d',
  }),
}

export default function Select({ name, label, options, isDisabled, ...rest }) {
  const classes = useStyles();
  const selectRef = useRef(null);
  const { fieldName, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: "state.value",
      getValue: (ref) => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return ref.state.value.map((option) => option.value);
        }
        if (!ref.state.value) {
          return '';
        }
        return ref.state.value.value;
      },
    });
  }, [fieldName, registerField, rest.isMulti]);//eslint-disable-line

  return (
    <Box className='inpuRoot'>
      <Grid container >
        {label &&
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography component="div" align="left" className='inputLabelBox'>
              {!isDisabled &&
                <FontAwesomeIcon icon={faPencilAlt} className='inputIconField' />
              }
              <span className='inputLabelDefault'>&nbsp;{label}:</span>
            </Typography>
          </Grid>
        }
        <Grid item xs={12} sm={12} md={12} lg={12} className="modalProfileRow">
          <ReactSelect
            isDisabled={isDisabled}
            styles={customStyles}
            className={classes.textField}
            ref={selectRef}
            classNamePrefix="react-select"
            options={options}
            {...rest}
          />
          {error &&
            <Typography component="div" align="left" className='inputLabelBoxError'>
              <FontAwesomeIcon icon={faTimesCircle} className='inputIconFieldError' />
              <span className='inputLabelFieldError'>{error}</span>
            </Typography>
          }
        </Grid>
      </Grid>
    </Box>
  );
};
