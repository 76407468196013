import { IResetPasswordUserResponse } from 'Module/ResetPasswordUser/Interface';
import { IUsers } from 'Module/Users/Interface';
import Api from 'Shared/Services/Api/index';
import GetSystemAuthUtil from 'Shared/Utils/ConfigEnvironment/GetSystemAuth/index';

const setChangePasswordService = async (fields: [IUsers]) => {
  return new Promise<IResetPasswordUserResponse>(async (resolve, reject) => {
    let regReturn: IResetPasswordUserResponse = {
      status: '200',
      message: '',
    };
    try {
      const _url = '/usersblock';
      await Api.post(_url,
        {
          "systemauth": GetSystemAuthUtil(process.env.REACT_APP_ENVIRONMENT),
          "registers": fields
        })
        .then(resp => {
          resolve(regReturn);
        })
        .catch(error => {
          console.log('SetResetPasswordUser.Service.SetChangePassword.error');
          console.log(error);
          let msgerror = error.stack ? error.stack : 'Ocorreu um erro!';
          if (error.response && error.response.data) {
            msgerror = error.response.data.msgErr;
          }
          if (error.response && error.response.result) {
            msgerror = error.response.result.msgErr;
          }

          regReturn.status = '400';
          regReturn.message = JSON.stringify(msgerror);
          regReturn.msgErro = JSON.stringify(error);
          reject(regReturn)
        });
    }
    catch (err) {
      console.log('SetResetPasswordUser.Service.SetChangePassword.err');
      console.log(err);

      let _msgError = 'Ocorreu um erro!';
      regReturn.status = '400';
      regReturn.message = _msgError;
      regReturn.msgErro = JSON.stringify(err);
      reject(regReturn)
    }
  });
}

export default setChangePasswordService;