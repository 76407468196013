import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHomeAlt,
} from '@fortawesome/free-solid-svg-icons';
import {
  Box, Grid, Typography, Breadcrumbs, Button,
} from '@mui/material';

import { Link } from 'react-router-dom';
import { Form } from '@unform/web';

import { HOME, PRODUCTS } from 'Shared/Constant/Routes';
import { IProductNewPage } from '../Interface';
import ProductHeaderPage from './ProductHeader/index';
import FieldsByGroupCustomPage from 'Shared/Components/Form/FieldsByGroupCustom/index';
import ModalQuestionYesNoPage from 'Shared/Components/ModalQuestionYesNo/index';
import { IModalQuestionYesNoPage } from 'Shared/Components/ModalQuestionYesNo/Interface';
import TypeAnswerConstant from 'Shared/Constant/TypeAnswer/index';
import { IProducts } from 'Module/Products/Interface';

function ProductNewPage(props: IProductNewPage) {
  const formDefault = React.useRef<any>(null);

  const [productUpdate, setProductUpdate] = React.useState<IProducts>({});
  const [productIDUpdate, setProductIDUpdate] = React.useState<string>('');

  const [fieldActive, setFieldActive] = React.useState<boolean>(true);

  const handleChangeField = (event: any, field?: string) => {
    switch (field) {
      case 'active':
        setFieldActive(event.value)
        break;
      case 'codigoFornec':
        const _value = event && event.target ? event.target.value : event.value;
        if (_value && props.listSuppliers && props.listSuppliers.length > 0) {
          const _findSupplier = props.listSuppliers.find(elS => elS?.codigo === _value);
          if (_findSupplier) {
            formDefault.current.setFieldValue('nomeFornec', _findSupplier.nome);
            formDefault.current.setFieldValue('documentFornec', _findSupplier.documento);
            formDefault.current.setFieldValue('codFornecPrinc', _findSupplier.codigoPrincipal);
          }
          else {
            formDefault.current.setFieldValue('nomeFornec', '');
            formDefault.current.setFieldValue('documentFornec', '');
            formDefault.current.setFieldValue('codFornecPrinc', '');
          }
        }
        break;
      default:
        break;
    }
  }

  const handleSubmit = (data: any) => {
    let _reg: IProducts = {
      codigo: data.codigo,
      nome: data.nome.toUpperCase(),
      ean: data.ean,
      codigoFornec: data.codigoFornec,
      documentFornec: data.documentFornec,
      nomeFornec: data.nomeFornec.toUpperCase(),
      codFornecPrinc: data.codFornecPrinc,
      codProdutoPrinc: data.codProdutoPrinc,
      qtUnitCx: data.qtUnitCx,
      active: fieldActive,
      userupdate: props.profile.userid,
      ipupdate: props.publicIPInfo,
      deviceupdate: 'Portal',
    }
    setProductUpdate(_reg);
    setProductIDUpdate('');
    if (props.toggleShowModalQuestionYesNo) {
      props.toggleShowModalQuestionYesNo(true);
    }
  }

  React.useEffect(() => {
    setFieldActive(props.item.active ? props.item.active : false);
  }, [props.item, props.dateUpdateInfo])

  const toggleAnswer = (key: string) => {
    if (props.toggleShowModalQuestionYesNo) {
      props.toggleShowModalQuestionYesNo(false)
    }

    switch (key) {
      case TypeAnswerConstant.YES:
      case TypeAnswerConstant.OK:
        props.toggleSetProducts(productUpdate, productIDUpdate);
        break;
      default:
        break;
    }
  }

  const handlerModalQuestion: IModalQuestionYesNoPage = {
    ...props,
    titlePage: 'Confirmação',
    question: 'Deseja alterar o registro?',
    toggleAnswer
  }

  return (
    <Box id="ProductNew" key="ProductNew" className="ChildrenRoot">
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography component="div" align="left" sx={{ alignContent: 'center', alignItems: 'center' }}>
            <Box id="BreadcrumbsBox" className="Breadcrumbs">
              <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" to={HOME}>
                  <FontAwesomeIcon icon={faHomeAlt} />
                </Link>
                <Link color="inherit" to={PRODUCTS}>
                  Produtos
                </Link>
              </Breadcrumbs>
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <ProductHeaderPage {...props} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Form
            ref={formDefault}
            id="myFormDefault"
            name="myFormDefault"
            onSubmit={handleSubmit}
          >
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                {props.isTypeGroupEdit &&
                  <Typography component="div" align="left" sx={{ padding: '0px 5px 5px 0px' }}>
                    <Button
                      variant="contained"
                      color="success"
                      className="ButtonPrimary"
                      type="submit"
                    >
                      <span>Salvar detalhes</span>
                    </Button>
                  </Typography>
                }
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography component="div" align="left">
                  <FieldsByGroupCustomPage
                    expanded={true}
                    title='Informações do Produto'
                    structure={props.structure}
                    fields={props.item}
                    isTypeGroupEdit={props.isTypeGroupEdit ? true : false}
                    dateUpdateInfo={props.dateUpdateInfo}
                    handleChange={handleChangeField}
                  />
                </Typography>
              </Grid>
            </Grid>
          </Form>
        </Grid>
      </Grid>
      {props.showModalQuestionYesNo &&
        <ModalQuestionYesNoPage {...handlerModalQuestion} />
      }
    </Box>
  )
}

export default ProductNewPage;
