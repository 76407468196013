import momenttz from 'moment-timezone';
// import { data } from '../../Mock/TargetsProduct/index';
import Api from 'Shared/Services/Api/index';
import GetSystemAuthUtil from 'Shared/Utils/ConfigEnvironment/GetSystemAuth/index';
import convertToNumber from 'Shared/Utils/ConvertToNumber';

import { ITargetsProduct, ITargetsProductFilters, ITargetsProductResponse } from '../../Interface/index';

const validFilters = (element: ITargetsProduct, filters: ITargetsProductFilters) => {
  let isFilter = true
  if (filters.codProduto && Number(element.codProduto) !== Number(filters.codProduto)) {
    isFilter = false
  }
  if (filters.ean && element.ean !== filters.ean) {
    isFilter = false
  }
  if (filters.nomeProduto && !element.nomeProduto?.includes(filters.nomeProduto)) {
    isFilter = false
  }

  if (filters.codigo && Number(element.codigo) !== Number(filters.codigo)) {
    isFilter = false
  }
  if (filters.documento && element.documento !== filters.documento) {
    isFilter = false
  }
  if (filters.nome && !element.nome?.includes(filters.nome)) {
    isFilter = false
  }

  if (filters.codigoFornec && Number(element.codigoFornec) !== Number(filters.codigoFornec)) {
    isFilter = false
  }
  if (filters.documentFornec && element.documentFornec !== filters.documentFornec) {
    isFilter = false
  }
  if (filters.codFornecPrinc && Number(element.codFornecPrinc) !== Number(filters.codFornecPrinc)) {
    isFilter = false
  }

  if (filters.nomeFornec && !element.nomeFornec?.includes(filters.nomeFornec)) {
    isFilter = false
  }

  return isFilter;
}

export const getTargetsProductAssociadoService = async (_params: ITargetsProductFilters) => {
  return new Promise<ITargetsProductResponse>(async (resolve, reject) => {
    let _arrayReg: [ITargetsProduct?] = [];
    let regReturn: ITargetsProductResponse = {
      status: '200',
      message: '',
    };

    try {
      let _url = _params.nome ? `/produtometabynomeassociado/${_params.nome}` :
        _params.codigo ? `/produtometabyassociado/${Number(_params.codigo).toString()}` : `/produtometabyprincipalassociado/${Number(_params.codClientePrinc).toString()}`;

      let _config: any = {
        "systemauth": GetSystemAuthUtil(process.env.REACT_APP_ENVIRONMENT),
      };

      if (_params.codigoFornec) {
        _config.codigofornec = Number(_params.codigoFornec)
      }
      else if (_params.codFornecPrinc) {
        _config.codfornecprinc = Number(_params.codFornecPrinc)
      }

      await Api.get(_url, { params: _config })
        .then(resp => {
          if (resp.data.data && resp.data.data.length > 0) {
            for (let reg of resp.data.data) {

              const _codProduto = reg.codProduto.value ? reg.codProduto.value.padStart(6, '0') : '000000';
              const _codProdutoPrinc = reg.codProdutoPrinc.value ? reg.codProdutoPrinc.value.padStart(6, '0') : '000000';
              const _codClientePrinc = reg.codClientePrinc.value ? reg.codClientePrinc.value.padStart(4, '0') : '0000';
              const _codFornecPrinc = reg.codFornecPrinc.value ? reg.codFornecPrinc.value.padStart(6, '0') : '000000';
              const _codigo = reg.codigo.value ? reg.codigo.value.padStart(4, '0') : '0000';
              const _codigoFornec = reg.codigoFornec.value ? reg.codigoFornec.value.padStart(6, '0') : '000000';

              let _item: ITargetsProduct = {};

              if (_params.agrupar === 'true') {
                let _qtCxFaturada = 0;
                let _valorBase = 0;
                let _qtdeBase = 0;

                let _metaFaturamento = 0;
                let _faturamento = 0;
                let _percentFaturmento = 0;

                const _validExist = _arrayReg.find(regFind => regFind?.ean === reg.ean.value && regFind?.codFornecPrinc === _codFornecPrinc && regFind?.codClientePrinc === _codClientePrinc);
                if (_validExist) {
                  if (_validExist && !_validExist.isMeta && reg.isMeta.value === 1) {
                    _validExist.isMeta = true;
                  }

                  const _index = _arrayReg.indexOf(_validExist);
                  _qtCxFaturada = Number(convertToNumber(_validExist.qtCxFaturada));
                  _valorBase = Number(convertToNumber(_validExist.valorBase));
                  _qtdeBase = Number(convertToNumber(_validExist.qtdeBase));

                  _metaFaturamento = Number(convertToNumber(_validExist.metaFaturamento));
                  _faturamento = Number(convertToNumber(_validExist.faturamento));
                  _percentFaturmento = Number(convertToNumber(_validExist.percentFaturmento));

                  _qtCxFaturada += Number(reg.qtCxFaturada.value);
                  _valorBase += Number(reg.valorBase.value);
                  _qtdeBase += Number(reg.qtdeBase.value);

                  _metaFaturamento += Number(reg.metaFaturamento.value);
                  _faturamento += Number(reg.faturamento.value);
                  _percentFaturmento = _metaFaturamento ? (_faturamento / _metaFaturamento) * 100 : 0;


                  _validExist.qtCxFaturada = _qtCxFaturada.toLocaleString("pt-BR", { minimumFractionDigits: 0, maximumFractionDigits: 0 });
                  _validExist.valorBase = _valorBase.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                  _validExist.qtdeBase = _qtdeBase.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 });

                  _validExist.metaFaturamento = _metaFaturamento.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                  _validExist.faturamento = _faturamento.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                  _validExist.percentFaturmento = _percentFaturmento.toLocaleString("pt-BR", { minimumIntegerDigits: 2, minimumFractionDigits: 2, maximumFractionDigits: 2 });

                  _arrayReg[_index] = _validExist;

                }
                else {
                  _item = {
                    id: reg.guidcontrol.value,
                    codProduto: _codProduto,
                    nomeProduto: reg.nomeProduto.value,
                    ean: reg.ean.value,
                    codProdutoPrinc: _codProdutoPrinc,
                    isMeta: reg.isMeta.value === 1 ? true : false,

                    codigo: _codigo,
                    codClientePrinc: _codClientePrinc,
                    nome: reg.nome.value,
                    documento: reg.documento.value,

                    codigoFornec: _codigoFornec,
                    documentFornec: reg.documentFornec.value,
                    nomeFornec: reg.nomeFornec.value,
                    codFornecPrinc: _codFornecPrinc,

                    qtCxFaturada: Number(reg.qtCxFaturada.value).toLocaleString("pt-BR", { minimumFractionDigits: 0, maximumFractionDigits: 0 }),
                    valorBase: Number(reg.valorBase.value).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                    qtdeBase: Number(reg.qtdeBase.value).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }),

                    metaMix: Number(reg.metaMix.value).toLocaleString("pt-BR", { minimumFractionDigits: 0, maximumFractionDigits: 0 }).padStart(4, '0'),
                    mix: Number(reg.mix.value).toLocaleString("pt-BR", { minimumFractionDigits: 0, maximumFractionDigits: 0 }).padStart(4, '0'),
                    percentMix: Number(reg.metaMix.value ? (Number(reg.mix.value) / Number(reg.metaMix.value)) * 100 : 0).toLocaleString("pt-BR", { minimumIntegerDigits: 2, minimumFractionDigits: 2, maximumFractionDigits: 2 }),

                    metaFaturamento: Number(reg.metaFaturamento.value).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                    faturamento: Number(reg.faturamento.value).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                    percentFaturmento: Number(reg.metaFaturamento.value ? (Number(reg.faturamento.value) / Number(reg.metaFaturamento.value)) * 100 : 0).toLocaleString("pt-BR", { minimumIntegerDigits: 2, minimumFractionDigits: 2, maximumFractionDigits: 2 }),

                    criadoEm: momenttz(reg.criadoEm.value).tz('America/Sao_Paulo').format('DD/MM/YYYY HH:mm'),
                    alteradoEm: momenttz(reg.alteradoEm.value).tz('America/Sao_Paulo').format('DD/MM/YYYY HH:mm'),
                  }

                  if (validFilters(_item, _params)) {
                    _arrayReg.push(_item);
                  }
                }

              }
              else {
                _item = {
                  id: reg.guidcontrol.value,
                  codProduto: _codProduto,
                  nomeProduto: reg.nomeProduto.value,
                  ean: reg.ean.value,
                  codProdutoPrinc: _codProdutoPrinc,
                  isMeta: reg.isMeta.value === 1 ? true : false,

                  codigo: _codigo,
                  nome: reg.nome.value,
                  documento: reg.documento.value,

                  codigoFornec: _codigoFornec,
                  documentFornec: reg.documentFornec.value,
                  nomeFornec: reg.nomeFornec.value,
                  codFornecPrinc: _codFornecPrinc,

                  qtCxFaturada: Number(reg.qtCxFaturada.value).toLocaleString("pt-BR", { minimumFractionDigits: 0, maximumFractionDigits: 0 }),
                  valorBase: Number(reg.valorBase.value).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                  qtdeBase: Number(reg.qtdeBase.value).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }),

                  metaMix: Number(reg.metaMix.value).toLocaleString("pt-BR", { minimumFractionDigits: 0, maximumFractionDigits: 0 }).padStart(4, '0'),
                  mix: Number(reg.mix.value).toLocaleString("pt-BR", { minimumFractionDigits: 0, maximumFractionDigits: 0 }).padStart(4, '0'),
                  percentMix: Number(reg.metaMix.value ? (Number(reg.mix.value) / Number(reg.metaMix.value)) * 100 : 0).toLocaleString("pt-BR", { minimumIntegerDigits: 2, minimumFractionDigits: 2, maximumFractionDigits: 2 }),

                  metaFaturamento: Number(reg.metaFaturamento.value).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                  faturamento: Number(reg.faturamento.value).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                  percentFaturmento: Number(reg.metaFaturamento.value ? (Number(reg.faturamento.value) / Number(reg.metaFaturamento.value)) * 100 : 0).toLocaleString("pt-BR", { minimumIntegerDigits: 2, minimumFractionDigits: 2, maximumFractionDigits: 2 }),

                  criadoEm: momenttz(reg.criadoEm.value).tz('America/Sao_Paulo').format('DD/MM/YYYY HH:mm'),
                  alteradoEm: momenttz(reg.alteradoEm.value).tz('America/Sao_Paulo').format('DD/MM/YYYY HH:mm'),
                }

                if (validFilters(_item, _params)) {
                  _arrayReg.push(_item);
                }

              }
            }
          }

          regReturn.list = _arrayReg;
          resolve(regReturn);
        })
        .catch(error => {
          console.log(error);
          let msgerror = error.stack ? error.stack : 'Ocorreu um erro!';
          if (error.response && error.response.data) {
            msgerror = error.response.data.msgErr;
          }
          if (error.response && error.response.result) {
            msgerror = error.response.result.msgErr;
          }

          regReturn.status = '400';
          regReturn.message = msgerror;
          regReturn.msgErro = JSON.stringify(error);
          reject(regReturn)
        });
    }
    catch (err) {
      console.log('AssociatesReport.Service.getAll.err');
      console.log(err);

      let _msgError = 'Ocorreu um erro!';
      regReturn.status = '400';
      regReturn.message = _msgError;
      regReturn.msgErro = JSON.stringify(err);
      reject(regReturn)
    }
  });
}