import { IFieldStructure } from "Shared/Interfaces/ObjectSalesforce";

const List: IFieldStructure[] = [
  { nameField: 'id', label: 'Id', group: 'Informações do Relatório', viewFormMain: true, orderFormMain: 0, viewFormDetail: true, orderFormDetail: 19, edit: false, type: 'string', options: [], hiddenFormMain: true, columnFile: '' },
  { nameField: 'codCliente', label: 'Cod. Associado', group: 'Informações do Relatório', viewFormMain: true, orderFormMain: 2, viewFormDetail: true, orderFormDetail: 2, edit: false, type: 'string', options: [], hiddenFormMain: false, width: "45px", columnFile: '' },
  { nameField: 'nomeCliente', label: 'Nome Associado', group: 'Informações do Relatório', viewFormMain: true, orderFormMain: 3, viewFormDetail: true, orderFormDetail: 3, edit: false, type: 'string', options: [], hiddenFormMain: false, columnFile: 'Associado (Matriz/Filial)' },
  { nameField: 'documentoCliente', label: 'Doc. Associado', group: 'Informações do Relatório', viewFormMain: false, orderFormMain: 99, viewFormDetail: true, orderFormDetail: 4, edit: false, type: 'string', options: [], hiddenFormMain: false, columnFile: 'Associado (MatrizFilial) CNPJ' },

  { nameField: 'numeroNota', label: 'Nro. NF', group: 'Informações do Relatório', viewFormMain: true, orderFormMain: 4, viewFormDetail: true, orderFormDetail: 5, edit: false, type: 'string', options: [], hiddenFormMain: false, columnFile: 'Nr. Nota' },
  { nameField: 'codProduto', label: 'Cod. Produto', group: 'Informações do Relatório', viewFormMain: true, orderFormMain: 5, viewFormDetail: true, orderFormDetail: 6, edit: false, type: 'string', options: [], hiddenFormMain: false, width: "45px", columnFile: 'Cód.' },
  { nameField: 'nomeProduto', label: 'Descrição', group: 'Informações do Relatório', viewFormMain: true, orderFormMain: 6, viewFormDetail: true, orderFormDetail: 7, edit: false, type: 'string', options: [], hiddenFormMain: false, columnFile: 'Produto' },
  { nameField: 'ean', label: 'EAN', group: 'Informações do Relatório', viewFormMain: true, orderFormMain: 7, viewFormDetail: true, orderFormDetail: 8, edit: false, type: 'string', options: [], hiddenFormMain: false, columnFile: 'EAN' },

  { nameField: 'codFornecedor', label: 'Cod. Fornecedor', group: 'Informações do Relatório', viewFormMain: true, orderFormMain: 8, viewFormDetail: true, orderFormDetail: 9, edit: false, type: 'string', options: [], hiddenFormMain: false, width: "45px", columnFile: 'Cód. Fornec' },
  { nameField: 'nomeFornecedor', label: 'Nome Fornecedor', group: 'Informações do Relatório', viewFormMain: true, orderFormMain: 9, viewFormDetail: true, orderFormDetail: 10, edit: false, type: 'string', options: [], hiddenFormMain: false, columnFile: 'Fornecedor (Matriz/Filial)' },
  { nameField: 'codFornPrincipal', label: 'Cod. Forn. Principal', group: 'Informações do Relatório', viewFormMain: true, orderFormMain: 10, viewFormDetail: true, orderFormDetail: 11, edit: false, type: 'string', options: [], hiddenFormMain: false, width: "45px", columnFile: '' },
  { nameField: 'nomeFornPrincipal', label: 'Nome Forn. Principal', group: 'Informações do Relatório', viewFormMain: false, orderFormMain: 99, viewFormDetail: true, orderFormDetail: 12, edit: false, type: 'string', options: [], hiddenFormMain: false, columnFile: '' },

  { nameField: 'caixas', label: 'Caixas', group: 'Informações do Relatório', viewFormMain: true, orderFormMain: 11, viewFormDetail: true, orderFormDetail: 13, edit: false, type: 'string', options: [], hiddenFormMain: false, columnFile: 'Qtde' },
  { nameField: 'qtUnitCx', label: 'Qt. Unitario', group: 'Informações do Relatório', viewFormMain: false, orderFormMain: 99, viewFormDetail: true, orderFormDetail: 14, edit: false, type: 'string', options: [], hiddenFormMain: false, columnFile: '' },
  { nameField: 'vlrUnitario', label: 'Vlr Unitario', group: 'Informações do Relatório', viewFormMain: false, orderFormMain: 99, viewFormDetail: true, orderFormDetail: 15, edit: false, type: 'currency', options: [], hiddenFormMain: false, columnFile: 'Val. Unit.' },
  { nameField: 'vlrVenda', label: 'Vlr Venda', group: 'Informações do Relatório', viewFormMain: true, orderFormMain: 12, viewFormDetail: true, orderFormDetail: 16, edit: false, type: 'currency', options: [], hiddenFormMain: false, columnFile: 'Val. Final' },

  { nameField: 'criadoEm', label: 'Criado em', group: 'Informações do Relatório', viewFormMain: false, orderFormMain: 99, viewFormDetail: true, orderFormDetail: 17, edit: false, type: 'string', options: [], hiddenFormMain: false, columnFile: '' },
  { nameField: 'alteradoEm', label: 'Alterado em', group: 'Informações do Relatório', viewFormMain: false, orderFormMain: 99, viewFormDetail: true, orderFormDetail: 18, edit: false, type: 'string', options: [], hiddenFormMain: false, columnFile: '' },
  { nameField: 'dataFaturamento', label: 'Data Faturado', group: 'Informações do Relatório', viewFormMain: true, orderFormMain: 1, viewFormDetail: true, orderFormDetail: 1, edit: false, type: 'string', options: [], hiddenFormMain: false, defaultSort: 'asc', columnFile: 'Data Emissão' },
  { nameField: 'isAreaCentral', label: 'Área Central', group: 'Informações do Produto', viewFormMain: true, orderFormMain: 13, viewFormDetail: true, orderFormDetail: 20, edit: false, type: 'checkbox', options: [{ value: true, label: 'Sim' }, { value: false, label: 'Não' }], hiddenFormMain: false },
  // { nameField: 'isCadProduto', label: 'Produto Cadastrado?', group: 'Informações do Produto', viewFormMain: true, orderFormMain: 14, viewFormDetail: true, orderFormDetail: 21, edit: false, type: 'checkbox', options: [{ value: true, label: 'Sim' }, { value: false, label: 'Não' }], hiddenFormMain: false },
  // { nameField: 'isEanProduto', label: 'EAN Cadastrado?', group: 'Informações do Produto', viewFormMain: true, orderFormMain: 15, viewFormDetail: true, orderFormDetail: 22, edit: false, type: 'checkbox', options: [{ value: true, label: 'Sim' }, { value: false, label: 'Não' }], hiddenFormMain: false },

];

export default List;