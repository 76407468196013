import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHomeAlt,
} from '@fortawesome/free-solid-svg-icons';
import {
  Box, Grid, Typography, Breadcrumbs, Button,
} from '@mui/material';

import { Link } from 'react-router-dom';
import { Form } from '@unform/web';

import { HOME, SUPPLIERS } from 'Shared/Constant/Routes';
import { ISupplierDetailPage } from '../Interface';
import SupplierHeaderPage from './SupplierHeader/index';
import FieldsByGroupCustomPage from 'Shared/Components/Form/FieldsByGroupCustom/index';
import ModalQuestionYesNoPage from 'Shared/Components/ModalQuestionYesNo/index';
import { IModalQuestionYesNoPage } from 'Shared/Components/ModalQuestionYesNo/Interface';
import TypeAnswerConstant from 'Shared/Constant/TypeAnswer/index';
import { ISuppliers } from 'Module/Suppliers/Interface';

function SupplierDetailPage(props: ISupplierDetailPage) {
  const formDefault = React.useRef<any>(null);

  const [supplierUpdate, setSupplierUpdate] = React.useState<ISuppliers>({});
  const [supplierIDUpdate, setSupplierIDUpdate] = React.useState<string>('');

  const [fieldActive, setFieldActive] = React.useState<boolean>(false);
  const handleChangeCurrency = (event: any, value: any, maskedValue: any) => {
    debugger //handleChangeCurrency
  };
  const handleChangeSelect = (event: any, field?: string) => {
    switch (field) {
      case 'active':
        setFieldActive(event.value)
        break;
      default:
        break;
    }
  }
  const handleSubmit = (data: any) => {
    const _id = props.item.id ? props.item.id : data.id;
    let _reg: ISuppliers = {
      codigo: data.codigo,
      nome: data.nome.toUpperCase(),
      documento: data.documento,
      codigoPrincipal: data.codigoPrincipal,
      active: fieldActive,
      userupdate: props.profile.userid,
      ipupdate: props.publicIPInfo,
      deviceupdate: 'Portal',
    }
    setSupplierUpdate(_reg);
    setSupplierIDUpdate(_id);
    if (props.toggleShowModalQuestionYesNo) {
      props.toggleShowModalQuestionYesNo(true);
    }
  }

  React.useEffect(() => {
    setFieldActive(props.item.active ? props.item.active : false);
  }, [props.item, props.dateUpdateInfo])

  const toggleAnswer = (key: string) => {
    switch (key) {
      case TypeAnswerConstant.YES:
      case TypeAnswerConstant.OK:
        props.toggleSetSuppliers(supplierUpdate, supplierIDUpdate);
        break;
      default:
        break;
    }
  }

  const handlerModalQuestion: IModalQuestionYesNoPage = {
    ...props,
    titlePage: 'Confirmação',
    question: 'Deseja alterar o registro?',
    toggleAnswer
  }

  return (
    <Box id="SupplierDetail" key="SupplierDetail" className="ChildrenRoot">
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography component="div" align="left" sx={{ alignContent: 'center', alignItems: 'center' }}>
            <Box id="BreadcrumbsBox" className="Breadcrumbs">
              <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" to={HOME}>
                  <FontAwesomeIcon icon={faHomeAlt} />
                </Link>
                <Link color="inherit" to={SUPPLIERS}>
                  Fornecedores
                </Link>
              </Breadcrumbs>
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <SupplierHeaderPage {...props} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Form
            ref={formDefault}
            id="myFormDefault"
            name="myFormDefault"
            onSubmit={handleSubmit}
          >
            <Grid container spacing={1}>
              <Grid item xs={6} sm={4} md={3} lg={3} xl={3}>
                {props.isTypeGroupEdit &&
                  <Typography component="div" align="left" sx={{ padding: '0px 5px 5px 0px' }}>
                    <Button
                      variant="contained"
                      color="success"
                      className="ButtonPrimary"
                      type="submit"
                    >
                      <span>Salvar detalhes</span>
                    </Button>
                  </Typography>
                }
              </Grid>
              <Grid item xs={6} sm={4} md={3} lg={3} xl={3}>
                {props.isTypeGroupEdit &&
                  <Typography component="div" align="left" sx={{ padding: '0px 5px 5px 0px' }}>
                    <Button
                      variant="contained"
                      color="success"
                      className="ButtonDanger"
                      type="button"
                    >
                      <span>Cadastrar Metas</span>
                    </Button>
                  </Typography>
                }
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography component="div" align="left">
                  <FieldsByGroupCustomPage
                    expanded={true}
                    title='Informações do Fornecedor'
                    structure={props.structure}
                    fields={props.item}
                    isTypeGroupEdit={props.isTypeGroupEdit ? true : false}
                    dateUpdateInfo={props.dateUpdateInfo}
                    handleChangeCurrency={handleChangeCurrency}
                    handleChange={handleChangeSelect}
                  />
                </Typography>
              </Grid>
            </Grid>
          </Form>
        </Grid>
      </Grid>
      {props.showModalQuestionYesNo &&
        <ModalQuestionYesNoPage {...handlerModalQuestion} />
      }
    </Box>
  )
}


export default SupplierDetailPage;
