import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHomeAlt,
} from '@fortawesome/free-solid-svg-icons';
import {
  Box, Grid, Typography, Breadcrumbs, Link,
} from '@mui/material';
import React from 'react';
import { HOME, PROFILE } from 'Shared/Constant/Routes';
import { IProfile } from '../Interface';

function ProfilePage(props: IProfile) {
  const [profile, setProfile] = React.useState<null | IProfile>(null);
  const toggleProfile = (data: IProfile) => {
    setProfile(data);
  };

  React.useEffect(() => {
    toggleProfile(props);
  }, [props]);

  return (
    <Box id="Profile" key="Profile" data-testid="Portal Campanha 2024" className="ChildrenRoot">
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography component="div" align="left">
            <Box id="BreadcrumbsBox" className="Breadcrumbs">
              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href={HOME}>
                  <FontAwesomeIcon icon={faHomeAlt} />
                </Link>
                <Link underline="hover" color="inherit" href={PROFILE}>
                  Perfil
                </Link>
              </Breadcrumbs>
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="ProfileRootRow">
          <Box className="ProfileForm">
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography component="div" align="center">
                  <Box className="ProfileBoxTitle">
                    <span className="ProfileTitle">Perfil</span>
                  </Box>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography component="div" align="left">
                  <span className="ProfileLabel">Id:</span>
                  <span>{profile ? profile.id : ''}</span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography component="div" align="left">
                  <span className="ProfileLabel">ID do usuário:</span>
                  <span>{profile ? profile.userid : ''}</span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography component="div" align="left">
                  <span className="ProfileLabel">Nome:</span>
                  <span>{profile ? profile.nome : ''}</span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography component="div" align="left">
                  <span className="ProfileLabel">Cargo:</span>
                  <span>{profile ? profile.cargo : ''}</span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography component="div" align="left">
                  <span className="ProfileLabel">CPF/CNPJ:</span>
                  <span>{profile ? profile.documento : ''}</span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography component="div" align="left">
                  <span className="ProfileLabel">E-Mail:</span>
                  <span>{profile ? profile.email : ''}</span>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ProfilePage;
