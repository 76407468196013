import { IFieldStructure } from "Shared/Interfaces/ObjectSalesforce";

const List: IFieldStructure[] = [
  { nameField: 'id', label: 'Id', group: 'Informações meta por associado', viewFormMain: true, orderFormMain: 0, viewFormDetail: true, orderFormDetail: 18, edit: false, type: 'string', options: [], hiddenFormMain: true },
  { nameField: 'codigo', label: 'Código', group: 'Informações meta por associado', viewFormMain: false, orderFormMain: 99, viewFormDetail: true, orderFormDetail: 2, edit: true, type: 'string', options: [], hiddenFormMain: false, width: "45px" },
  { nameField: 'nome', label: 'Nome', group: 'Informações meta por associado', viewFormMain: true, orderFormMain: 1, viewFormDetail: true, orderFormDetail: 3, edit: true, type: 'string', options: [], hiddenFormMain: false },
  { nameField: 'documento', label: 'CPF/CNPJ', group: 'Informações meta por associado', viewFormMain: false, orderFormMain: 99, viewFormDetail: true, orderFormDetail: 4, edit: true, type: 'string', options: [], hiddenFormMain: false },

  { nameField: 'codigoFornec', label: 'Código Fornecedor', group: 'Informações meta por associado', viewFormMain: false, orderFormMain: 99, viewFormDetail: true, orderFormDetail: 5, edit: true, type: 'string', options: [], hiddenFormMain: false, width: "45px" },
  { nameField: 'documentFornec', label: 'CNPJ Fornecedor', group: 'Informações meta por associado', viewFormMain: false, orderFormMain: 99, viewFormDetail: true, orderFormDetail: 6, edit: false, type: 'string', options: [], hiddenFormMain: false },
  { nameField: 'nomeFornec', label: 'Nome Fornecedor', group: 'Informações meta por associado', viewFormMain: false, orderFormMain: 99, viewFormDetail: true, orderFormDetail: 7, edit: false, type: 'string', options: [], hiddenFormMain: false },

  { nameField: 'valorBase', label: 'Vlr Base', group: 'Informações meta por associado', viewFormMain: false, orderFormMain: 99, viewFormDetail: true, orderFormDetail: 8, edit: true, type: 'currency', options: [], hiddenFormMain: false },
  { nameField: 'qtdeBase', label: 'Qtde Base', group: 'Informações meta por associado', viewFormMain: false, orderFormMain: 99, viewFormDetail: true, orderFormDetail: 9, edit: true, type: 'currency', options: [], hiddenFormMain: false },

  { nameField: 'mix', label: 'Mix', group: 'Informações meta por associado', viewFormMain: true, orderFormMain: 2, viewFormDetail: true, orderFormDetail: 10, edit: true, type: 'number', options: [], hiddenFormMain: false, width: "50px" },
  { nameField: 'metaMix', label: 'Meta mix', group: 'Informações meta por associado', viewFormMain: true, orderFormMain: 3, viewFormDetail: true, orderFormDetail: 11, edit: true, type: 'number', options: [], hiddenFormMain: false, width: "50px", cellStyle: { fontWeight: "bold", backgroundColor: "#f4f4f4", color: "#3a6d51" } },
  { nameField: 'percentMix', label: '% Mix/Meta', group: 'Informações meta por associado', viewFormMain: true, orderFormMain: 4, viewFormDetail: true, orderFormDetail: 12, edit: true, type: 'currency', options: [], hiddenFormMain: false, width: "95px", cellStyle: { fontWeight: "bold" } },


  { nameField: 'faturamento', label: 'Faturamento', group: 'Informações meta por associado', viewFormMain: true, orderFormMain: 5, viewFormDetail: true, orderFormDetail: 13, edit: true, type: 'currency', options: [], hiddenFormMain: false, width: "60px" },
  { nameField: 'metaFaturamento', label: 'Meta faturamento', group: 'Informações meta por associado', viewFormMain: true, orderFormMain: 6, viewFormDetail: true, orderFormDetail: 14, edit: true, type: 'currency', options: [], hiddenFormMain: false, width: "60px", cellStyle: { fontWeight: "bold", backgroundColor: "#f4f4f4", color: "#3a6d51" } },
  { nameField: 'percentFaturmento', label: '% Fat/Meta', group: 'Informações meta por associado', viewFormMain: true, orderFormMain: 7, viewFormDetail: true, orderFormDetail: 15, edit: true, type: 'currency', options: [], hiddenFormMain: false, defaultSort: 'desc', width: "100px", cellStyle: { fontWeight: "bold" } },

  { nameField: 'active', label: 'Ativo', group: 'Informações meta por associado', viewFormMain: false, orderFormMain: 99, viewFormDetail: true, orderFormDetail: 1, edit: true, type: 'checkbox', options: [{ value: true, label: 'Sim' }, { value: false, label: 'Não' }], hiddenFormMain: false },
  { nameField: 'criadoEm', label: 'Criado em', group: 'Informações meta por associado', viewFormMain: false, orderFormMain: 99, viewFormDetail: true, orderFormDetail: 16, edit: false, type: 'string', options: [], hiddenFormMain: false },
  { nameField: 'alteradoEm', label: 'Alterado em', group: 'Informações meta por associado', viewFormMain: false, orderFormMain: 99, viewFormDetail: true, orderFormDetail: 17, edit: false, type: 'string', options: [], hiddenFormMain: false },
];

export default List;