import * as React from 'react';
import sortBy from 'sort-by';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { Column } from '@material-table/core';
import { Table } from 'Shared/Components/Mui/Table/index';
import RegisterStructure from 'Shared/Constant/AssociateTargetModal/RecordStructure';
import { IHomeTargetsSupplier } from 'Module/HomeUser/Interface/HomeTargetsSupplier';
import { IFieldStructure } from 'Shared/Interfaces/ObjectSalesforce';
import { CircularProgress, Grid, Typography } from '@mui/material';
import { KEY_HOMETARGETSSUPPLIER_SELECTED } from 'Shared/Constant/Cache';
import { IAssociateDetailPage } from 'Module/AssociateDetail/Interface';
import ModalQuestionYesNoPage from 'Shared/Components/ModalQuestionYesNo/index';
import { IModalQuestionYesNoPage } from 'Shared/Components/ModalQuestionYesNo/Interface';
import TypeAnswerConstant from 'Shared/Constant/TypeAnswer/index';
import InputCurrencyMask from 'Shared/Components/Form/InputCurrencyMask/index';
import InputCustom from 'Shared/Components/Form/InputReactCustom';

import { Form } from '@unform/web';
function PaperComponent(props: PaperProps) {
  const nodeRef = React.useRef(null);
  return (
    <Draggable
      nodeRef={nodeRef}
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper ref={nodeRef} {...props} />
    </Draggable>
  );
}

export default function AssociateTargetModalPage(props: IAssociateDetailPage) {

  const formDefault = React.useRef<any>(null);
  const [open] = React.useState(true);

  const [columns, setColumns] = React.useState<Column<object>[]>([]);
  const toggleSetColumns = (values: Column<object>[]) => {
    setColumns(values);
  }

  const [oldData, setOldData] = React.useState<IHomeTargetsSupplier>({});
  const [newData, setNewData] = React.useState<IHomeTargetsSupplier>({});
  const toggleEditItemModal = async (oldData: any, newData: any) => {
    if (oldData.metaFaturamento !== newData.metaFaturamento) {
      let _preparar = newData.metaFaturamento.replace('R$', '').replace(/\./g, '').replace(/,/g, '.').trim();
      let _numero = Number(_preparar * 10).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      newData.metaFaturamento = _numero
    }

    setOldData(oldData);
    setNewData(newData);

    if (props.toggleShowModalQuestionYesNo) {
      props.toggleShowModalQuestionYesNo(true);
    }
  }

  const toggleAnswerModal = (key: string) => {
    switch (key) {
      case TypeAnswerConstant.YES:
      case TypeAnswerConstant.OK:
        const dataUpdate: IHomeTargetsSupplier[] = data.map((el: IHomeTargetsSupplier) => el);
        let index = -1;
        if (dataUpdate.find((elOld: IHomeTargetsSupplier) => elOld.id === oldData.id)) {
          const _itemAux = dataUpdate.find((elOld: IHomeTargetsSupplier) => elOld.id === oldData.id) || {};
          index = dataUpdate.indexOf(_itemAux);
        }
        if (index > -1) {
          dataUpdate[index] = newData;
        }
        setData(dataUpdate);
        if (props.toggleSetAssociateTarget) {
          const _id: string = newData.id ? newData.id : oldData.id ? oldData.id : '';
          const _itemNew: IHomeTargetsSupplier = {
            codigo: newData.codigo,
            nome: newData.nome,
            codigoFornec: newData.codigoFornec,
            metaMix: newData.metaMix ? Number(newData.metaMix.replace(/\./g, '').replace(',', '.')).toLocaleString("pt-BR", { minimumFractionDigits: 0, maximumFractionDigits: 0 }).padStart(4, '0') : '0000',
            metaFaturamento: newData.metaFaturamento ? newData.metaFaturamento.replace(/\./g, '').replace(',', '.') : '0',
            userupdate: props.profile.userid,
            ipupdate: props.publicIPInfo,
            deviceupdate: 'Portal',
          }
          props.toggleSetAssociateTarget(_itemNew, _id)
        }

        break;
      default:
        break;
    }
  }

  const [data, setData] = React.useState<object[]>([]);
  const toggleSetData = (values: [IHomeTargetsSupplier?]) => {
    const _array: object[] = values.map(reg => reg || {});
    setData(_array);
  }

  const handleSubmit = (data: any) => {

  };

  const toggleSetItemSelected = (item: any) => {
    const _item: IHomeTargetsSupplier = item;

    if (props.toggleSetTargetSupplierSelected) {
      props.toggleSetTargetSupplierSelected(_item);
    }
    if (props.toggleSetCache) {
      props.toggleSetCache(KEY_HOMETARGETSSUPPLIER_SELECTED, _item);
    }
  }

  const getFieldsStructure = React.useCallback((list: IFieldStructure[]) => {
    let _array: Column<object>[] = [];
    if (list && list.length > 0) {
      for (let regField of list.sort(sortBy('orderFormMain'))) {
        let _column: Column<object> = {}
        if (regField.viewFormMain) {
          switch (regField.type) {
            case 'checkbox':
              _column = {
                title: regField.label,
                field: regField.nameField,
                editable: regField.edit ? 'always' : 'never',
                hidden: regField.hiddenFormMain,
                editComponent: (props) => {
                  return (
                    <input
                      type="checkbox"
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  );
                },
                render: (rowdata: any) => {
                  if (regField.nameField === 'active') {
                    return (<input type="checkbox" checked={rowdata.active} readOnly />)
                  }
                  else if (regField.nameField === 'isMeta') {
                    return (<input type="checkbox" checked={rowdata.isMeta} readOnly />)
                  }
                }
              }
              break;
            case 'currency':
              _column = {
                title: regField.label,
                field: regField.nameField,
                customSort: (a, b) => parseFloat(`a.${regField.nameField}`.replace(',', '.')) - parseFloat(`b.${regField.nameField}`.replace(',', '.')), // Ordena numericamente
                type: 'string', // numeric
                align: 'right',
                editable: regField.edit ? 'always' : 'never',
                hidden: regField.hiddenFormMain,
                editComponent: (props) => {
                  return (
                    <InputCurrencyMask
                      autoComplete="off"
                      disabled={!regField.edit}
                      id={regField.nameField}
                      name={regField.nameField}
                      label=''
                      // autoComplete={regField.nameField}
                      type="currency"
                      defaultValue={props.value}
                      handleChangeCustom={(e: any) => {
                        props.onChange(e.target.value);
                        // let _preparar = e.target.value.replace('R$', '').replace(/\./g, '').replace(/,/g, '.').trim();
                        // let _numero = Number(_preparar * 10).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                        // props.onChange(_numero);
                      }}
                      classCustom="inputCustomDefault"
                    />
                  );
                },
              }
              break;
            case 'number':
              _column = {
                title: regField.label,
                field: regField.nameField,
                customSort: (a, b) => parseFloat(`a.${regField.nameField}`.replace(',', '.')) - parseFloat(`b.${regField.nameField}`.replace(',', '.')), // Ordena numericamente
                type: 'string', // numeric
                align: 'right',
                editable: regField.edit ? 'always' : 'never',
                hidden: regField.hiddenFormMain,
                editComponent: (props) => {
                  return (
                    <InputCustom
                      autoComplete="off"
                      disabled={!regField.edit}
                      id={regField.nameField}
                      name={regField.nameField}
                      label=''
                      // autoComplete={regField.nameField}
                      type="number"
                      defaultValue={props.value}
                      handleChangeCustom={(e: any) => { props.onChange(e.target.value); }}
                      classCustom="inputCustomDefault"
                    />
                  );
                },
              }
              break;
            default:
              _column = {
                title: regField.label,
                field: regField.nameField,
                type: 'string',
                editable: regField.edit ? 'always' : 'never',
                hidden: regField.hiddenFormMain
              }
              break;
          }
          if (regField.defaultSort) {
            _column.defaultSort = regField.defaultSort === 'asc' ? 'asc' : 'desc';
          }
          if (regField.width) {
            _column.width = regField.width;
          }
          if (regField.cellStyle) {
            _column.cellStyle = regField.cellStyle;
          }


          switch (_column.field) {
            case 'qtCxFaturada':
              _column.customSort = (a: any, b: any) => parseFloat(a.qtCxFaturada.replace(/\./g, '').replace(/,/g, '.')) - parseFloat(b.qtCxFaturada.replace(/\./g, '').replace(/,/g, '.'));
              _column.type = 'numeric';
              break;
            case 'vlrVenda':
              _column.customSort = (a: any, b: any) => parseFloat(a.vlrVenda.replace(/\./g, '').replace(/,/g, '.')) - parseFloat(b.vlrVenda.replace(/\./g, '').replace(/,/g, '.'));
              _column.type = 'numeric';
              break;
            case 'valorBase':
              _column.customSort = (a: any, b: any) => parseFloat(a.valorBase.replace(/\./g, '').replace(/,/g, '.')) - parseFloat(b.valorBase.replace(/\./g, '').replace(/,/g, '.'));
              _column.type = 'numeric';
              // _column.render = (rowData: any) => parseFloat(rowData.valorBase.replace(/\./g, '').replace(/,/g, '.')).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', currencyDisplay: 'narrowSymbol' }).replace('R$', ''); // Formata o número no formato pt-br sem moeda
              break;
            case 'qtdeBase':
              _column.customSort = (a: any, b: any) => parseFloat(a.qtdeBase.replace(/\./g, '').replace(/,/g, '.')) - parseFloat(b.qtdeBase.replace(/\./g, '').replace(/,/g, '.'));
              _column.type = 'numeric';
              break;
            case 'metaMix':
              _column.customSort = (a: any, b: any) => parseFloat(a.metaMix.replace(/\./g, '').replace(/,/g, '.')) - parseFloat(b.metaMix.replace(/\./g, '').replace(/,/g, '.'));
              _column.type = 'numeric';
              break;
            case 'mix':
              _column.customSort = (a: any, b: any) => parseFloat(a.mix.replace(/\./g, '').replace(/,/g, '.')) - parseFloat(b.mix.replace(/\./g, '').replace(/,/g, '.'));
              _column.type = 'numeric';
              break;
            case 'percentMix':
              _column.customSort = (a: any, b: any) => parseFloat(a.percentMix.replace(/\./g, '').replace(/,/g, '.')) - parseFloat(b.percentMix.replace(/\./g, '').replace(/,/g, '.'));
              _column.type = 'numeric';
              break;
            case 'metaFaturamento':
              _column.customSort = (a: any, b: any) => parseFloat(a.metaFaturamento.replace(/\./g, '').replace(/,/g, '.')) - parseFloat(b.metaFaturamento.replace(/\./g, '').replace(/,/g, '.'));
              _column.type = 'numeric';
              break;
            case 'faturamento':
              _column.customSort = (a: any, b: any) => parseFloat(a.faturamento.replace(/\./g, '').replace(/,/g, '.')) - parseFloat(b.faturamento.replace(/\./g, '').replace(/,/g, '.'));
              _column.type = 'numeric';
              break;
            case 'percentFaturmento':
              _column.customSort = (a: any, b: any) => parseFloat(a.percentFaturmento.replace(/\./g, '').replace(/,/g, '.')) - parseFloat(b.percentFaturmento.replace(/\./g, '').replace(/,/g, '.'));
              _column.type = 'numeric';
              break;
            case 'caixas':
              _column.customSort = (a: any, b: any) => parseFloat(a.caixas.replace(/\./g, '').replace(/,/g, '.')) - parseFloat(b.caixas.replace(/\./g, '').replace(/,/g, '.'));
              _column.type = 'numeric';
              break;
            case 'qtUnitCx':
              _column.customSort = (a: any, b: any) => parseFloat(a.qtUnitCx.replace(/\./g, '').replace(/,/g, '.')) - parseFloat(b.qtUnitCx.replace(/\./g, '').replace(/,/g, '.'));
              _column.type = 'numeric';
              break;
            case 'vlrUnitario':
              _column.customSort = (a: any, b: any) => parseFloat(a.vlrUnitario.replace(/\./g, '').replace(/,/g, '.')) - parseFloat(b.vlrUnitario.replace(/\./g, '').replace(/,/g, '.'));
              _column.type = 'numeric';
              break;
            default:
              break;
          }


          _array.push(_column)
        }
      }
    }
    toggleSetColumns(_array);

  }, [])

  const handleClose = () => {
    if (props.toggleShowAssociateTargetModal) {
      props.toggleShowAssociateTargetModal(false);
    }
  };

  React.useEffect(() => {
    getFieldsStructure(RegisterStructure);
    toggleSetData(props?.listModalTargetsSupplier || []);
  }, [props.listModalTargetsSupplier, props.dateUpdateInfo, getFieldsStructure])

  const handlerModalQuestion: IModalQuestionYesNoPage = {
    ...props,
    titlePage: 'Confirmação',
    question: 'Deseja alterar o registro?',
    toggleAnswer: toggleAnswerModal,
    toggleShowModalQuestion: props.toggleShowModalQuestionYesNo
  }

  return (
    <div>
      <Form
        ref={formDefault}
        id="myFormDefault"
        name="myFormDefault"
        onSubmit={handleSubmit}
      >
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
            <Grid container spacing={1}>
              <Grid item xs={6} sm={8} md={9} lg={10} xl={10}>
                <Typography component={'div'} align='left'>
                  <span className='AssociatesReportModalTitle'>{props?.titleModalTargetsSupplier || 'Metas'}</span>
                  {props.showLoadingUpdateTable && <span>&nbsp;&nbsp;<CircularProgress size={25} /></span>}
                </Typography>

              </Grid>
              <Grid item xs={6} sm={4} md={3} lg={2} xl={2}>
                <Typography component={'div'} align='right'>
                  <Button
                    variant="contained"
                    color="success"
                    className="ButtonDanger"
                    type="button"
                    autoFocus
                    onClick={handleClose}
                  >
                    <span>Fechar</span>
                  </Button>
                </Typography>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <DialogContentText component={'div'}>
              <Table dataParam={data}
                columns={columns}
                title='Metas fornecedores'
                href={''}
                actionsColumnIndex={0}
                toggleSelectItem={toggleSetItemSelected}
                toggleShowLoadingUpdateTable={props.toggleShowLoadingUpdateTable}
                editItems={true}
                toggleEditItem={toggleEditItemModal}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
          </DialogActions>
        </Dialog>
      </Form>
      {props.showModalQuestionYesNo &&
        <ModalQuestionYesNoPage {...handlerModalQuestion} />
      }
    </div>
  );
}
