import momenttz from 'moment-timezone';
// import { data } from '../../Mock/TargetsAssociate/index';

import Api from 'Shared/Services/Api/index';
import GetSystemAuthUtil from 'Shared/Utils/ConfigEnvironment/GetSystemAuth/index';
import { ITargetsAssociate, ITargetsAssociateFilters, ITargetsAssociateResponse } from 'Module/SuppliersReport/Interface';

const validFilters = (element: ITargetsAssociate, filters: ITargetsAssociateFilters) => {
  let isFilter = true
  if (filters.codigo && element.codigo !== filters.codigo) {
    isFilter = false
  }
  if (filters.documento && element.documento !== filters.documento) {
    isFilter = false
  }
  if (filters.nome && !element.nome?.includes(filters.nome)) {
    isFilter = false
  }
  if (filters.codigoFornec && element.codigoFornec !== filters.codigoFornec) {
    isFilter = false
  }

  return isFilter;
}


export const getTargetsAssociateService = async (params: ITargetsAssociateFilters) => {
  return new Promise<ITargetsAssociateResponse>(async (resolve, reject) => {
    let _arrayReg: [ITargetsAssociate?] = [];
    let regReturn: ITargetsAssociateResponse = {
      status: '200',
      message: '',
    };

    try {
      let _url = params.codigoFornec ? `/metabyfornecedor/${Number(params.codigoFornec).toString()}` :
        params.codigo ? `/metabyassociado/${Number(params.codigo).toString()}` : '/meta';
      let _config = {
        "systemauth": GetSystemAuthUtil(process.env.REACT_APP_ENVIRONMENT)
      };
      await Api.get(_url, { params: _config })
        .then(resp => {
          if (resp.data.data && resp.data.data.length > 0) {
            for (let reg of resp.data.data) {
              const _item = {
                id: reg.guidcontrol.value,
                codigo: reg.codigo.value ? reg.codigo.value.padStart(4, '0') : '0000',
                nome: reg.nome.value,
                documento: reg.documento.value,
                codigoFornec: reg.codigoFornec.value ? reg.codigoFornec.value.padStart(6, '0') : '000000',
                documentFornec: reg.documentFornec.value,
                nomeFornec: reg.nomeFornec.value,
                codClientePrinc: reg.codClientePrinc.value ? reg.codClientePrinc.value.padStart(4, '0') : '0000',
                codFornecPrinc: reg.codFornecPrinc.value ? reg.codFornecPrinc.value.padStart(6, '0') : '000000',
                valorBase: Number(reg.valorBase.value).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                qtdeBase: Number(reg.qtdeBase.value).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                metaMix: Number(reg.metaMix.value).toLocaleString("pt-BR", { minimumFractionDigits: 0, maximumFractionDigits: 0 }).padStart(4, '0'),
                mix: Number(reg.mix.value).toLocaleString("pt-BR", { minimumFractionDigits: 0, maximumFractionDigits: 0 }).padStart(4, '0'),
                percentMix: Number(reg.metaMix.value ? (Number(reg.mix.value) / Number(reg.metaMix.value)) * 100 : 0).toLocaleString("pt-BR", { minimumIntegerDigits: 2, minimumFractionDigits: 2, maximumFractionDigits: 2 }),

                metaFaturamento: Number(reg.metaFaturamento.value).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                faturamento: Number(reg.faturamento.value).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                percentFaturmento: Number(reg.metaFaturamento.value ? (Number(reg.faturamento.value) / Number(reg.metaFaturamento.value)) * 100 : 0).toLocaleString("pt-BR", { minimumIntegerDigits: 2, minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                criadoEm: momenttz(reg.criadoEm.value).tz('America/Sao_Paulo').format('DD/MM/YYYY HH:mm'),
                alteradoEm: momenttz(reg.alteradoEm.value).tz('America/Sao_Paulo').format('DD/MM/YYYY HH:mm'),
              }
              if (validFilters(_item, params)) {
                _arrayReg.push(_item)
              }
            }
          }

          regReturn.list = _arrayReg;
          resolve(regReturn);
        })
        .catch(error => {
          console.log(error);
          let msgerror = error.stack ? error.stack : 'Ocorreu um erro!';
          if (error.response && error.response.data) {
            msgerror = error.response.data.msgErr;
          }
          if (error.response && error.response.result) {
            msgerror = error.response.result.msgErr;
          }

          regReturn.status = '400';
          regReturn.message = msgerror;
          regReturn.msgErro = JSON.stringify(error);
          reject(regReturn)
        });
    }
    catch (err) {
      console.log('AssociatesReport.Service.getAll.err');
      console.log(err);

      let _msgError = 'Ocorreu um erro!';
      regReturn.status = '400';
      regReturn.message = _msgError;
      regReturn.msgErro = JSON.stringify(err);
      reject(regReturn)
    }
  });
}