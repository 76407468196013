import momenttz from 'moment-timezone';

import Api from 'Shared/Services/Api/index';
import GetSystemAuthUtil from 'Shared/Utils/ConfigEnvironment/GetSystemAuth/index';

import { IReports, IReportsFilters, IReportsResponse } from '../../Interface/index';

const validFilters = (element: IReports, filters: IReportsFilters) => {
  let isFilter = true
  if (filters.nomecliente && !element.nomeCliente?.includes(filters.nomecliente)) {
    isFilter = false
  }
  if (filters.nomeproduto && !element.nomeProduto?.includes(filters.nomeproduto)) {
    isFilter = false
  }
  if (filters.nomefornecedor && !element.nomeFornecedor?.includes(filters.nomefornecedor)) {
    isFilter = false
  }

  return isFilter;
}

export const getReportsDatesService = async (params: IReportsFilters) => {
  return new Promise<IReportsResponse>(async (resolve, reject) => {
    let _arrayReg: [IReports?] = [];
    let regReturn: IReportsResponse = {
      status: '200',
      message: '',
    };

    try {
      let _config = {
        "systemauth": GetSystemAuthUtil(process.env.REACT_APP_ENVIRONMENT),
        "datainicio": params.datainicio,
        "datafim": params.datafim
      };

      await Api.get('/relatoriobydatafaturado', { params: _config })
        .then(resp => {
          if (resp.data.data && resp.data.data.length > 0) {
            for (let reg of resp.data.data) {
              const _item: IReports = {
                id: reg.guidcontrol.value,
                codCliente: reg.codCliente.value ? reg.codCliente.value.padStart(4, '0') : '0000',
                nomeCliente: reg.nomeCliente.value,
                documentoCliente: reg.documentoCliente.value,
                dataFaturamento: momenttz(reg.dataFaturamento.value).tz('America/Sao_Paulo').format('DD/MM/YYYY'),
                numeroNota: reg.numeroNota.value,
                codProduto: reg.codProduto.value ? reg.codProduto.value.padStart(6, '0') : '000000',
                nomeProduto: reg.nomeProduto.value,
                ean: reg.ean.value,
                codFornecedor: reg.codFornecedor.value ? reg.codFornecedor.value.padStart(6, '0') : '000000',
                nomeFornecedor: reg.nomeFornecedor.value,
                codFornPrincipal: reg.codFornPrincipal.value ? reg.codFornPrincipal.value.padStart(6, '0') : '000000',
                nomeFornPrincipal: reg.nomeFornPrincipal.value,
                isAreaCentral: reg.isAreaCentral.value === 1 ? true : false,
                isCadProduto: reg.isCadProduto.value === 1 ? true : false,
                isEanProduto: reg.isEanProduto.value === 1 ? true : false,

                caixas: Number(reg.caixas.value).toLocaleString("pt-BR", { minimumFractionDigits: 0, maximumFractionDigits: 0 }),
                qtUnitCx: Number(reg.qtUnitCx.value).toLocaleString("pt-BR", { minimumFractionDigits: 0, maximumFractionDigits: 0 }),
                vlrUnitario: Number(reg.vlrUnitario.value).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                vlrVenda: Number(reg.vlrVenda.value).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }),

                criadoEm: momenttz(reg.criadoEm.value).tz('America/Sao_Paulo').format('DD/MM/YYYY'),
                alteradoEm: momenttz(reg.alteradoEm.value).tz('America/Sao_Paulo').format('DD/MM/YYYY'),
              }

              if (validFilters(_item, params)) {
                _arrayReg.push(_item)
              }
            }
          }

          regReturn.list = _arrayReg;
          resolve(regReturn);
        })
        .catch(error => {
          console.log(error);
          let msgerror = error.stack ? error.stack : 'Ocorreu um erro!';
          if (error.response && error.response.data) {
            msgerror = error.response.data.msgErr;
          }
          if (error.response && error.response.result) {
            msgerror = error.response.result.msgErr;
          }

          regReturn.status = '400';
          regReturn.message = msgerror;
          regReturn.msgErro = JSON.stringify(error);
          reject(regReturn)
        });
    }
    catch (err) {
      console.log('Report.Service.getAll.err');
      console.log(err);

      let _msgError = 'Ocorreu um erro!';
      regReturn.status = '400';
      regReturn.message = _msgError;
      regReturn.msgErro = JSON.stringify(err);
      reject(regReturn)
    }
  });
}