//https://github.com/thiagozanetti/react-intl-currency-input
import React, { useState, useEffect, useRef } from 'react';
import IntlCurrencyInput from "react-intl-currency-input"
import { useField } from '@unform/core';
import { useStyles } from './styleMaterial';

import {
  Grid
  , Typography
  , Box
} from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTimesCircle,
  faPencilAlt
} from '@fortawesome/free-solid-svg-icons'

export default function Input({ name, label, classCustom, handleChangeCustom, type, disabled, ...rest }) {

  const currencyConfig = {
    locale: "pt-BR",
    formats: {
      number: {
        BRL: {
          style: "currency",
          currency: "BRL",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        },
      },
    },
  };

  const classes = useStyles();
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  const [classInput, setClassInput] = useState('inputCustomDefault');

  useEffect(() => {
    if (classCustom) {
      setClassInput(classCustom);
    }
  }, [classCustom]);

  useEffect(() => {
    if (inputRef.current) {
      registerField({
        name: fieldName,
        ref: inputRef.current,
        path: 'value',
      });
    }
  }, [fieldName, registerField]);

  return (
    <Box className={classes.root}>
      <Grid container>
        {label &&
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography component="div" align="left" className={classes.labelBox}>
              {!disabled &&
                <FontAwesomeIcon icon={faPencilAlt} className={classes.iconField} />
              }
              <span className={classes.labelField}>{label}:</span>
            </Typography>
          </Grid>
        }
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {/* <input ref={inputRef} defaultValue={defaultValue} disabled={disabled} {...rest} className={classInput} /> */}
          <IntlCurrencyInput
            // ref={inputRef}
            currency="BRL"
            config={currencyConfig}
            onChange={handleChangeCustom}
            defaultValue={defaultValue}
            {...rest}
            disabled={disabled}
            className={classInput}
          />

          {error &&
            <Typography component="div" align="left" className={classes.labelBoxError}>
              <FontAwesomeIcon icon={faTimesCircle} className={classes.iconFieldError} />
              <span className={classes.labelFieldError}>{error}</span>
            </Typography>
          }
        </Grid>
      </Grid>
    </Box>
  );

}
