import momenttz from 'moment-timezone';
import Api from 'Shared/Services/Api/index';
import GetSystemAuthUtil from 'Shared/Utils/ConfigEnvironment/GetSystemAuth/index';
import { ISuppliers, ISuppliersFilters, ISuppliersResponse } from 'Module/Suppliers/Interface';

export const getSupplierByDocument = async (params: ISuppliersFilters) => {
  return new Promise<ISuppliersResponse>(async (resolve, reject) => {
    let _arrayReg: [ISuppliers?] = [];
    let regReturn: ISuppliersResponse = {
      status: '200',
      message: '',
    };

    try {

      let _config = {
        "systemauth": GetSystemAuthUtil(process.env.REACT_APP_ENVIRONMENT)
      };

      await Api.get(`/fornecedorbydocument/${params.documento}`, { params: _config })
        .then(resp => {
          if (resp.data.data && resp.data.data.length > 0) {
            for (let reg of resp.data.data) {

              _arrayReg.push({
                id: reg.guidcontrol.value,
                codigo: reg.codigo.value ? reg.codigo.value.padStart(6, '0') : '000000',
                nome: reg.nome.value,
                documento: reg.documento.value,
                email: reg.email.value,
                codigoPrincipal: reg.codigoPrincipal.value ? reg.codigoPrincipal.value.padStart(6, '0') : '000000',
                active: reg.active.value === 1 ? true : false,
                criadoEm: reg.criadoEm.value ? momenttz(reg.criadoEm.value).tz('America/Sao_Paulo').format('DD/MM/YYYY HH:mm') : '',
                alteradoEm: reg.alteradoEm.value ? momenttz(reg.alteradoEm.value).tz('America/Sao_Paulo').format('DD/MM/YYYY HH:mm') : '',
              })

            }
          }

          regReturn.list = _arrayReg;
          resolve(regReturn);
        })
        .catch(error => {
          console.log(error);
          let msgerror = error.stack ? error.stack : 'Ocorreu um erro!';
          if (error.response && error.response.data) {
            msgerror = error.response.data.msgErr;
          }
          if (error.response && error.response.result) {
            msgerror = error.response.result.msgErr;
          }

          regReturn.status = '400';
          regReturn.message = msgerror;
          regReturn.msgErro = JSON.stringify(error);
          reject(regReturn)
        });
    }
    catch (err) {
      console.log('getAssociatesByDocument.Service.getAll.err');
      console.log(err);

      let _msgError = 'Ocorreu um erro!';
      regReturn.status = '400';
      regReturn.message = _msgError;
      regReturn.msgErro = JSON.stringify(err);
      reject(regReturn)
    }
  });
}