import React from 'react';
import momenttz from 'moment-timezone';

import { ILayout, ILayoutPage } from 'Module/Layout/Interface';
import { ISuppliersReport, ISuppliersReportContext, ISuppliersReportFilters, ISuppliersReportPage, ISuppliersReportResponse, ITargetsAssociate, ITargetsAssociateFilters, ITargetsAssociateResponse } from '../Interface';
import SuppliersReportPage from '../Page/index';
import { getSuppliersReportService } from '../Service/Get/index';
import { getTargetsAssociateService } from '../Service/GetTargetsAssociate/index';
import { KEY_SUPPLIERSREPORT_SELECTED } from 'Shared/Constant/Cache';
// import { getTargetsProductService } from 'Module/TargetsProduct/Service/Get';
import { getTargetsProductAssociadoService } from 'Module/TargetsProduct/Service/GetByAssociado/index';

import { ITargetsProduct, ITargetsProductFilters, ITargetsProductResponse } from 'Module/TargetsProduct/Interface';

function SuppliersReportController(props: ILayoutPage) {
  const [contextSuppliersReport] = React.useState<ISuppliersReportContext>({ result: [] });
  const [showLoadingThisPage, setShowLoadingThisPage] = React.useState<boolean>(true);
  const [dateUpdateInfo, setDateUpdateInfo] = React.useState<string>(momenttz().tz('America/Sao_Paulo').format());
  const [filters, setFilters] = React.useState<ISuppliersReportFilters>({ codigo: '', nome: '', documento: '', active: 'Todos' });

  const [showModalAssociateDetail, setShowModalAssociateDetail] = React.useState<boolean>(false);
  const toggleShowModalAssociateDetail = (value: boolean) => {
    setShowModalAssociateDetail(value);
  }

  const [titleModalTargetsAssociate, setTitleModalTargetsAssociate] = React.useState<string>('Meta');
  const toggleTitleModalTargetsAssociate = (value: string) => {
    setTitleModalTargetsAssociate(value);
  }

  const [listModalTargetsAssociate, setListModalTargetsAssociate] = React.useState<[ITargetsAssociate?]>([])
  const toggleGetTargetsAssociate = React.useCallback(async (filters: ITargetsAssociateFilters) => {
    await getTargetsAssociateService(filters)
      .then((response: ITargetsAssociateResponse) => {
        setListModalTargetsAssociate(response.list || []);
      }).catch((error: ITargetsAssociateResponse) => {
        console.log(error.message);
      })
  }, [])

  const [showModalAssociateProductDetail, setShowModalAssociateProductDetail] = React.useState<boolean>(false);
  const toggleShowModalAssociateProductDetail = (value: boolean) => {
    setShowModalAssociateProductDetail(value);
  }

  const [titleModalTargetProduct, setTitleModalTargetProduct] = React.useState<string>('Meta');
  const toggleTitleModalTargetProduct = (value: string) => {
    setTitleModalTargetProduct(value);
  }

  const [listModalTargetsProduct, setListModalTargetsProduct] = React.useState<[ITargetsProduct?]>([])
  const toggleGetTargetsProduct = React.useCallback(async (filters: ITargetsProductFilters) => {

    await getTargetsProductAssociadoService(filters)
      .then((response: ITargetsProductResponse) => {
        setListModalTargetsProduct(response.list || []);
      }).catch((error: ITargetsProductResponse) => {
        console.log(error.message);
      })

  }, [])

  const toggleChangeState = (field: string, value: any) => {
    switch (field) {
      case 'dateUpdateInfo':
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        break;
      case 'showLoadingThisPage':
        setShowLoadingThisPage(value);
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        break;
      case 'filters':
        setFilters(value);
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        break;
      default:
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        break;
    }
  }

  const [listSuppliersReport, setListSuppliersReport] = React.useState<[ISuppliersReport?]>([]);
  const toggleGetSuppliersReport = React.useCallback(async (filters: ISuppliersReportFilters) => {
    toggleChangeState('showLoadingThisPage', true);
    toggleChangeState('filters', filters)

    if (props.toggleGetTargetProductGoalSupplier) {
      const _filter: ITargetsProductFilters = {
        codProduto: '',
        nomeProduto: '',
        ean: '',
        codProdutoPrinc: '',

        codigo: '',
        nome: '',
        documento: '',
        codClientePrinc: '',

        codigoFornec: filters.codigo,
        nomeFornec: filters.nome,
        documentFornec: filters.documento,
        codFornecPrinc: filters.codigoPrincipal,

        agrupar: 'true',
      }
      props.toggleGetTargetProductGoalSupplier(_filter);
    }

    await getSuppliersReportService(filters)
      .then((response: ISuppliersReportResponse) => {
        setListSuppliersReport(response.list || []);
        toggleChangeState('showLoadingThisPage', false);
        toggleChangeState('dateUpdateInfo', '');

        const _array: [ISuppliersReport?] = response.list || [];
        props.toggleSetSuppliersReportList(_array)
      })
      .catch((error: ISuppliersReportResponse) => {
        let layoutAlert: ILayout = {
          context: props.layout.context,
          titleMessage: 'Pesquisar registros',
          typeMessage: 'error',
          message: error.message,
          loading: false,
        };

        props.toggleSetLayout(layoutAlert);
        toggleChangeState('showLoadingThisPage', false);
        toggleChangeState('dateUpdateInfo', '');
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleSetSuppliersReport = (suppliersReport: ISuppliersReport, action: string) => {

  };


  React.useEffect(() => {
    toggleGetSuppliersReport(filters);
  }, [filters, toggleGetSuppliersReport]);

  React.useEffect(() => {
    let _showModal = window.location.hash.includes('#Create') ? true : false;
    toggleChangeState('showNewLeadModal', _showModal);
    if (props.toggleDelCache) {
      props.toggleDelCache(KEY_SUPPLIERSREPORT_SELECTED)
    }
  }, [props])



  React.useEffect(() => {
    let _array: [ITargetsAssociate?] = [];
    if (props.suppliersReportSelected && props.suppliersReportSelected.id) {
      toggleTitleModalTargetsAssociate(`${props.suppliersReportSelected.codigo} - ${props.suppliersReportSelected.nome} `);

      const filters: ITargetsAssociateFilters = {
        codigoFornec: props.suppliersReportSelected.codigo
      }
      toggleGetTargetsAssociate(filters)
    }
    setListModalTargetsAssociate(_array)
  }, [props.suppliersReportSelected, props.dateUpdateInfo, toggleGetTargetsAssociate])

  React.useEffect(() => {
    if (props.targetSupplierSelected && props.targetSupplierSelected.id) {
      toggleTitleModalTargetProduct(`${props.targetSupplierSelected.codigo}: ${props.targetSupplierSelected.nome} - ${props.targetSupplierSelected.codigoFornec}: ${props.targetSupplierSelected.nomeFornec} `);

      const filters: ITargetsProductFilters = {
        codigoFornec: props.targetSupplierSelected.codigoFornec,
        codigo: props.targetSupplierSelected.codigo,
      }
      toggleGetTargetsProduct(filters)
    }
  }, [props.targetSupplierSelected, props.dateUpdateInfo, toggleGetTargetsProduct])

  const handlersPage: ISuppliersReportPage = {
    ...props,
    context: contextSuppliersReport,
    listSuppliersReport,
    showLoadingThisPage,
    dateUpdateInfo,
    filters,
    itemSelected: {},
    showModalAssociateDetail,
    titleModalTargetsAssociate,
    listModalTargetsAssociate,
    showModalAssociateProductDetail,
    listModalTargetsProduct,
    titleModalTargetProduct,
    toggleShowModalAssociateDetail,
    toggleShowModalAssociateProductDetail,
    toggleChangeState,
    toggleGetSuppliersReport,
    toggleSetSuppliersReport,
  };

  return (
    <SuppliersReportPage {...handlersPage} />
  );
}

export default SuppliersReportController;
