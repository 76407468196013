import momenttz from 'moment-timezone';
// import { data } from '../../Mock/TargetsProduct/index';
import Api from 'Shared/Services/Api/index';
import GetSystemAuthUtil from 'Shared/Utils/ConfigEnvironment/GetSystemAuth/index';

import { ITargetsProduct, ITargetsProductFilters, ITargetsProductResponse } from '../../Interface/index';

const validFilters = (element: ITargetsProduct, filters: ITargetsProductFilters) => {
  let isFilter = true
  if (filters.codProduto && element.codProduto !== filters.codProduto) {
    isFilter = false
  }
  if (filters.ean && element.ean !== filters.ean) {
    isFilter = false
  }
  if (filters.nomeProduto && !element.nomeProduto?.includes(filters.nomeProduto)) {
    isFilter = false
  }

  if (filters.codigo && Number(element.codigo) !== Number(filters.codigo)) {
    isFilter = false
  }
  if (filters.documento && element.documento !== filters.documento) {
    isFilter = false
  }
  if (filters.nome && !element.nome?.includes(filters.nome)) {
    isFilter = false
  }

  if (filters.codigoFornec && Number(element.codigoFornec) !== Number(filters.codigoFornec)) {
    isFilter = false
  }
  if (filters.documentFornec && element.documentFornec !== filters.documentFornec) {
    isFilter = false
  }
  if (filters.nomeFornec && !element.nomeFornec?.includes(filters.nomeFornec)) {
    isFilter = false
  }

  return isFilter;
}


export const getTargetsProductSupplierService = async (params: ITargetsProductFilters) => {
  return new Promise<ITargetsProductResponse>(async (resolve, reject) => {
    let _arrayReg: [ITargetsProduct?] = [];
    let regReturn: ITargetsProductResponse = {
      status: '200',
      message: '',
    };

    try {
      let _url = params.nomeFornec ? `/produtometabynomefornecedor/${params.nomeFornec}` : `/produtometabyfornecedor/${Number(params.codigoFornec).toString()}`;
      let _config = {
        "systemauth": GetSystemAuthUtil(process.env.REACT_APP_ENVIRONMENT)
      };

      await Api.get(_url, { params: _config })
        .then(resp => {
          if (resp.data.data && resp.data.data.length > 0) {
            for (let reg of resp.data.data) {
              const _item: ITargetsProduct = {
                id: reg.guidcontrol.value,
                codProduto: reg.codProduto.value ? reg.codProduto.value.padStart(6, '0') : '000000',
                nomeProduto: reg.nomeProduto.value,
                ean: reg.ean.value,
                codProdutoPrinc: reg.codProdutoPrinc.value ? reg.codProdutoPrinc.value.padStart(6, '0') : '000000',
                isMeta: reg.isMeta.value === 1 ? true : false,

                codigo: reg.codigo.value ? reg.codigo.value.padStart(4, '0') : '0000',
                nome: reg.nome.value,
                documento: reg.documento.value,

                codigoFornec: reg.codigoFornec.value ? reg.codigoFornec.value.padStart(6, '0') : '000000',
                documentFornec: reg.documentFornec.value,
                nomeFornec: reg.nomeFornec.value,

                qtCxFaturada: Number(reg.qtCxFaturada.value).toLocaleString("pt-BR", { minimumFractionDigits: 0, maximumFractionDigits: 0 }),
                valorBase: Number(reg.valorBase.value).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                qtdeBase: Number(reg.qtdeBase.value).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }),

                metaMix: Number(reg.metaMix.value).toLocaleString("pt-BR", { minimumFractionDigits: 0, maximumFractionDigits: 0 }).padStart(4, '0'),
                mix: Number(reg.mix.value).toLocaleString("pt-BR", { minimumFractionDigits: 0, maximumFractionDigits: 0 }).padStart(4, '0'),
                percentMix: Number(reg.metaMix.value ? (Number(reg.mix.value) / Number(reg.metaMix.value)) * 100 : 0).toLocaleString("pt-BR", { minimumIntegerDigits: 2, minimumFractionDigits: 2, maximumFractionDigits: 2 }),

                metaFaturamento: Number(reg.metaFaturamento.value).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                faturamento: Number(reg.faturamento.value).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                percentFaturmento: Number(reg.metaFaturamento.value ? (Number(reg.faturamento.value) / Number(reg.metaFaturamento.value)) * 100 : 0).toLocaleString("pt-BR", { minimumIntegerDigits: 2, minimumFractionDigits: 2, maximumFractionDigits: 2 }),

                criadoEm: momenttz(reg.criadoEm.value).tz('America/Sao_Paulo').format('DD/MM/YYYY HH:mm'),
                alteradoEm: momenttz(reg.alteradoEm.value).tz('America/Sao_Paulo').format('DD/MM/YYYY HH:mm'),
              }

              if (validFilters(_item, params)) {
                _arrayReg.push(_item)
              }
            }

          }

          regReturn.list = _arrayReg;
          resolve(regReturn);
        })
        .catch(error => {
          console.log(error);
          let msgerror = error.stack ? error.stack : 'Ocorreu um erro!';
          if (error.response && error.response.data) {
            msgerror = error.response.data.msgErr;
          }
          if (error.response && error.response.result) {
            msgerror = error.response.result.msgErr;
          }

          regReturn.status = '400';
          regReturn.message = msgerror;
          regReturn.msgErro = JSON.stringify(error);
          reject(regReturn)
        });
    }
    catch (err) {
      console.log('AssociatesReport.Service.getAll.err');
      console.log(err);

      let _msgError = 'Ocorreu um erro!';
      regReturn.status = '400';
      regReturn.message = _msgError;
      regReturn.msgErro = JSON.stringify(err);
      reject(regReturn)
    }
  });
}