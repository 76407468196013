import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link } from '@mui/material';

function createData(
  name: string,
  link: string,
) {
  return { name, link };
}

const rows = [
  createData('Apresentação campanha', '/content/ApresentacaoCampanha.pdf'),
  createData('Vídeo campanha', '/content/VideoCampanhaRedemais2024.mp4'),
  createData('Imagem Monitores 1280x960 px', '/content/Img_Monitor1280x960.jpg'),
  createData('Imagem Monitores 1920x1080 px', '/content/Img_Monitor1920x1080.jpg'),
  createData('Imagem FEED 1080x1350 px', '/content/Feed_1080x1350px.jpg'),
  createData('Imagem Story 1080x1920 px', '/content/Story_1080x1920px.jpg'),
];

export default function HomeUserDocuments() {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ maxWidth: 650 }} aria-label="Documentos">
        <TableHead>
          <TableRow>
            <TableCell>
              <span className='HomeUserDocumentsTitle'>Documentos</span>
            </TableCell>
            <TableCell className='HomeUserDocumentsTitle' align="right">
              <span className='HomeUserDocumentsTitle'>Download</span>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">
                <Link
                  href={row.link}
                  target='_blank'
                >
                  <span><i className="fa fa-download"></i></span>
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}