import React from 'react';
import momenttz from 'moment-timezone';

import { ILayout, ILayoutPage } from 'Module/Layout/Interface';
import ProductDetailPage from '../Page/index';
import { IProductDetailPage } from '../Interface';
import { IProducts, IProductsContext, IProductsResponse } from 'Module/Products/Interface';
import { PRODUCTS } from 'Shared/Constant/Routes';
import RecordStructureConstant from 'Shared/Constant/Products/RecordStructure';
import { setProductsService } from '../Service/SetProduto/index';

function ProductDetailController(props: ILayoutPage) {
  const [contextProducts] = React.useState<IProductsContext>({ result: [] });
  const [dateUpdateInfo, setDateUpdateInfo] = React.useState<string>(momenttz().tz('America/Sao_Paulo').format());
  const [showLoadingThisPage, setShowLoadingThisPage] = React.useState<boolean>(true);
  const [item, setItem] = React.useState<IProducts>({});
  const toggleSetItem = (itemSelected: IProducts) => {
    setItem(itemSelected);
  }

  const [showModalQuestionYesNo, setShowModalQuestionYesNo] = React.useState<boolean>(false);
  const toggleShowModalQuestionYesNo = (value: boolean) => {
    setShowModalQuestionYesNo(value);
  }


  const toggleSetProducts = React.useCallback(async (product: IProducts, id: string) => {
    toggleChangeState('showLoadingThisPage', true);
    await setProductsService(product, id)
      .then((response: IProductsResponse) => {
        let layoutAlert: ILayout = {
          loading: false,
          typeMessage: 'success',
          titleMessage: 'Salvo',
          message: 'Registro salvo com sucesso',
        }
        props.toggleSetLayout(layoutAlert);
        toggleChangeState('showLoadingThisPage', false);
      })
      .catch((error: IProductsResponse) => {
        console.log(error.message);
        let layoutAlert: ILayout = {
          loading: false,
          typeMessage: 'error',
          titleMessage: 'Erro ao salvar',
          message: error.message,
        }
        props.toggleSetLayout(layoutAlert);
        toggleChangeState('showLoadingThisPage', false);
      })
  }, []);//eslint-disable-line


  const toggleChangeState = (field: string, value: any) => {
    switch (field) {
      case 'dateUpdateInfo':
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        break;
      case 'showLoadingThisPage':
        setShowLoadingThisPage(value);
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        break;
      case 'item':
        toggleSetItem(value);
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        break;
      default:
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        break;
    }
  }

  React.useEffect(() => {
    let _item: IProducts = {};
    if (props.productSelected) {
      _item = props.productSelected
    }
    toggleChangeState('item', _item);
    if (!_item.nome) {
      let layoutAlert: ILayout = {
        loading: false,
        typeMessage: 'error',
        titleMessage: 'Não encontrado',
        message: 'Erro ao pesquisar os detalhes do associado. Tente pesquisar novamente',
      }
      props.toggleSetLayout(layoutAlert);
      setTimeout(() => {
        window.location.href = `${PRODUCTS}#search`;
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.productSelected])


  const handlerPage: IProductDetailPage = {
    ...props,
    context: contextProducts,
    showLoadingThisPage,
    dateUpdateInfo,
    item,
    structure: RecordStructureConstant,
    toggleChangeState,
    toggleSetProducts,
    showModalQuestionYesNo,
    toggleShowModalQuestionYesNo,
  }
  return (
    <React.Fragment>
      <ProductDetailPage {...handlerPage} />
    </React.Fragment>
  )
}


export default ProductDetailController;
