import { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit,
} from '@fortawesome/free-solid-svg-icons';

import MaterialTable, { Column, Icons } from '@material-table/core';
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from '@mui/icons-material';
import { Container } from '@mui/material';
import React from 'react';

// interface IObjectColumn {
//   tableData: object
// }
const tableIcons: Icons<object> = {
  // eslint-disable-next-line react/display-name
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  // eslint-disable-next-line react/display-name
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  // eslint-disable-next-line react/display-name
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  // eslint-disable-next-line react/display-name
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  // eslint-disable-next-line react/display-name
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  // eslint-disable-next-line react/display-name
  Edit: forwardRef((props, ref) => <Edit fontSize="0.6rem" {...props} ref={ref} />),
  // eslint-disable-next-line react/display-name
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  // eslint-disable-next-line react/display-name
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  // eslint-disable-next-line react/display-name
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  // eslint-disable-next-line react/display-name
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  // eslint-disable-next-line react/display-name
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  // eslint-disable-next-line react/display-name
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  // eslint-disable-next-line react/display-name
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  // eslint-disable-next-line react/display-name
  Search: forwardRef((props, ref) => <Search fontSize="0.6rem" {...props} ref={ref} />),
  // eslint-disable-next-line react/display-name
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  // eslint-disable-next-line react/display-name
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  // eslint-disable-next-line react/display-name
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

type Props = {
  columns: Column<object>[];
  dataParam: object[];
  title?: string;
  href?: string;
  pageSize?: number;
  actionsColumnIndex?: number;
  toggleSelectItem?: Function;
  editItems?: boolean;
  toggleEditItem?: Function;
  groupFields?: boolean;
  toggleShowLoadingUpdateTable?: Function;
};

export function Table({ dataParam, columns, title, href, toggleSelectItem, actionsColumnIndex, pageSize, editItems, toggleEditItem, groupFields, toggleShowLoadingUpdateTable }: Props) {
  const [selectedRow, setSelectedRow] = React.useState<object>({});
  const [eDataSourceMessage, setEDataSourceMessage] = React.useState('Nenhum registro para ser mostrado');


  let _data: object[] = [];
  _data = dataParam;

  // React.useEffect(() => {
  //   if (toggleShowLoadingUpdateTable) {
  //     toggleShowLoadingUpdateTable(true);
  //     setEDataSourceMessage('Criando registros...');
  //   }

  //   if (dataParam.length === 0) {
  //     if (toggleShowLoadingUpdateTable) {
  //       toggleShowLoadingUpdateTable(false);
  //       setEDataSourceMessage('Nenhum registro para ser mostrado');
  //     }
  //   }
  //   else {
  //     const _numberTimeout = dataParam.length < 1500 ? 3000 : dataParam.length;
  //     setTimeout(() => {
  //       if (toggleShowLoadingUpdateTable) {
  //         toggleShowLoadingUpdateTable(false);
  //         setEDataSourceMessage('Nenhum registro para ser mostrado');
  //       }
  //     }, Number((_numberTimeout / 1500) * 1500));
  //   }
  // }, [dataParam]);

  return (
    <Container maxWidth="xl" id="tablecustomer">
      <MaterialTable
        style={{ fontSize: '0.8rem' }}
        columns={columns}
        data={_data}
        isLoading={false}
        actions={href ? [
          {
            icon: () => <Link
              color="inherit"
              to={href || window.location.pathname}
              state={{ data: _data }}
            >
              <FontAwesomeIcon icon={faEdit} />
            </Link>,
            tooltip: 'Detalhes',
            onClick: (event, rowData) => {
              if (toggleSelectItem) {
                toggleSelectItem(rowData);
              }
            }
          }
        ] : undefined}
        onRowClick={(evt, selectedRow) => {
          if (selectedRow) {
            setSelectedRow(selectedRow)
          }
        }}
        options={{
          // sorting: true,
          paging: true,
          pageSize: pageSize ? pageSize : 10,
          pageSizeOptions: [5, 10, 20, 50, 100, 300, 500, 1000],
          emptyRowsWhenPaging: false,
          headerStyle: { backgroundColor: '#DFE4D0', padding: '5px', fontWeight: 'bold', fontSize: '0.8rem' },
          grouping: groupFields,
          actionsColumnIndex: actionsColumnIndex ? actionsColumnIndex : 0,
          rowStyle: rowData => ({
            backgroundColor: (selectedRow === rowData) ? '#EEE' : '#FFF'
          })
        }}
        icons={tableIcons}
        localization={{
          grouping: {
            placeholder: 'Arraste os cabeçalhos aqui para agrupar por',
            groupedBy: 'Agrupado por',
          },
          pagination: {
            labelRowsPerPage: 'Linhas por página',
            labelDisplayedRows: '{count} de {from}-{to}',
            firstTooltip: 'Primeira página',
            previousTooltip: 'Página anterior',
            nextTooltip: 'Próxima página',
            lastTooltip: 'Última página',
          },
          toolbar: {
            nRowsSelected: '{0} linha(s) selecionadas',
            searchPlaceholder: 'Pesquisar',
            searchTooltip: 'Pesquisar',
            exportTitle: 'Exportar',
            exportAriaLabel: 'Exportar',
          },
          header: {
            actions: 'Ações',
          },
          body: {
            addTooltip: 'Adicionar',
            deleteTooltip: 'remover',
            editTooltip: 'Editar',
            editRow: {
              deleteText: 'Deseja realmente remover esse registro?',
              cancelTooltip: 'Cancelar',
              saveTooltip: 'Salvar',
            },
            emptyDataSourceMessage: eDataSourceMessage,
            filterRow: {
              filterTooltip: 'Filtro',
            },
          },
        }}
        title={title}
        editable={editItems ? {
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                if (toggleEditItem) {
                  toggleEditItem(oldData, newData)
                }
                resolve(true);
              }, 1000);
            }),
        } : undefined}
      />
    </Container>
  );
}
