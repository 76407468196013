import momenttz from 'moment-timezone';
import Api from 'Shared/Services/Api/index';
import GetSystemAuthUtil from 'Shared/Utils/ConfigEnvironment/GetSystemAuth/index';
// import { data } from '../../Mock/TargetSupplier/index';
import { IHomeTargetSupplierAssociate, IHomeTargetSupplierAssociateFilters, IHomeTargetSupplierAssociateResponse } from '../../Interface/HomeTargetSupplierAssociate';
import convertToNumber from 'Shared/Utils/ConvertToNumber';
import sortBy from 'sort-by';

const validFilters = (element: IHomeTargetSupplierAssociate, filters: IHomeTargetSupplierAssociateFilters) => {
  let isFilter = true
  if (filters.codigo && Number(element.codigo) !== Number(filters.codigo)) {
    isFilter = false
  }
  if (filters.documento && element.documento !== filters.documento) {
    isFilter = false
  }
  if (filters.documentFornec && element.documento !== filters.documentFornec) {
    isFilter = false
  }
  if (filters.nome && !element.nome?.includes(filters.nome)) {
    isFilter = false
  }
  if (filters.nomeFornec && !element.nomeFornec?.includes(filters.nomeFornec)) {
    isFilter = false
  }
  return isFilter;
}


export const getTargetSupplierAssociateService = async (params: IHomeTargetSupplierAssociateFilters) => {
  return new Promise<IHomeTargetSupplierAssociateResponse>(async (resolve, reject) => {
    let _arrayReg: [IHomeTargetSupplierAssociate?] = [];
    let regReturn: IHomeTargetSupplierAssociateResponse = {
      status: '200',
      message: '',
    };

    try {
      let _url = params.codigoFornec ? `/metabyfornecedor/${Number(params.codigoFornec).toString()}` :
        params.codFornecPrinc ? `/metabyfornecedorprincipal/${Number(params.codFornecPrinc).toString()}` : '/meta';

      let _config = {
        "systemauth": GetSystemAuthUtil(process.env.REACT_APP_ENVIRONMENT)
      };

      await Api.get(_url, { params: _config })
        .then(resp => {
          let _arrayResume: [IHomeTargetSupplierAssociate?] = [];
          if (resp.data.data && resp.data.data.length > 0) {
            const _regBase = resp.data.data.sort(sortBy("codigo.value"))
            for (let reg of _regBase) {
              const _item = {
                id: reg.guidcontrol.value,
                codigo: reg.codigo.value ? reg.codigo.value.padStart(4, '0') : '0000',
                nome: `${reg.codigo.value} - ${reg.nome.value}`,
                documento: reg.documento.value,
                codigoFornec: reg.codigoFornec.value ? reg.codigoFornec.value.padStart(6, '0') : '000000',
                documentFornec: reg.documentFornec.value,
                nomeFornec: `${reg.codigoFornec.value} - ${reg.nomeFornec.value}`,
                codClientePrinc: reg.codClientePrinc.value ? reg.codClientePrinc.value.padStart(4, '0') : '0000',
                codFornecPrinc: reg.codFornecPrinc.value ? reg.codFornecPrinc.value.padStart(6, '0') : '000000',
                valorBase: Number(reg.valorBase.value).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                qtdeBase: Number(reg.qtdeBase.value).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                metaMix: Number(reg.metaMix.value).toLocaleString("pt-BR", { minimumFractionDigits: 0, maximumFractionDigits: 0 }).padStart(4, '0'),
                mix: Number(reg.mix.value).toLocaleString("pt-BR", { minimumFractionDigits: 0, maximumFractionDigits: 0 }).padStart(4, '0'),
                percentMix: Number(reg.metaMix.value ? (Number(reg.mix.value) / Number(reg.metaMix.value)) * 100 : 0).toLocaleString("pt-BR", { minimumIntegerDigits: 2, minimumFractionDigits: 2, maximumFractionDigits: 2 }),

                metaFaturamento: Number(reg.metaFaturamento.value).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                faturamento: Number(reg.faturamento.value).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                percentFaturmento: Number(reg.metaFaturamento.value ? (Number(reg.faturamento.value) / Number(reg.metaFaturamento.value)) * 100 : 0).toLocaleString("pt-BR", { minimumIntegerDigits: 2, minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                criadoEm: momenttz(reg.criadoEm.value).tz('America/Sao_Paulo').format('DD/MM/YYYY HH:mm'),
                alteradoEm: momenttz(reg.alteradoEm.value).tz('America/Sao_Paulo').format('DD/MM/YYYY HH:mm'),
              }

              if (validFilters(_item, params)) {
                _arrayReg.push(_item);

                let _aux = _arrayResume.find(el => el?.codFornecPrinc === _item.codFornecPrinc && el?.documento === _item.documento);
                if (_aux) {
                  const _index = _arrayResume.indexOf(_aux);

                  let _newReg: IHomeTargetSupplierAssociate = {
                    id: _aux.id,
                    codigo: _aux.codigo,
                    nome: _aux.nome,
                    documento: _aux.documento,
                    codigoFornec: _aux.codigoFornec,
                    documentFornec: _aux.documentFornec,
                    nomeFornec: _aux.nomeFornec,
                    codClientePrinc: reg.codClientePrinc.value ? reg.codClientePrinc.value.padStart(4, '0') : '0000',
                    codFornecPrinc: reg.codFornecPrinc.value ? reg.codFornecPrinc.value.padStart(6, '0') : '000000',
                    valorBase: _aux.valorBase,
                    qtdeBase: _aux.qtdeBase,
                    metaMix: _aux.metaMix,
                    mix: _aux.mix,
                    percentMix: _aux.metaMix,
                    metaFaturamento: _aux.metaFaturamento,
                    faturamento: _aux.faturamento,
                    percentFaturmento: _aux.metaFaturamento,
                    criadoEm: _aux.criadoEm,
                    alteradoEm: _aux.alteradoEm,
                  }

                  const _auxValorBase = convertToNumber(_aux.valorBase);
                  const _valorBase = convertToNumber(_item.valorBase);
                  const _novoValorBase = Number(_auxValorBase) + Number(_valorBase);
                  _newReg.valorBase = Number(_novoValorBase).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 });

                  const _auxQtdeBase = convertToNumber(_aux.qtdeBase);
                  const _qtdeBase = convertToNumber(_item.qtdeBase);
                  const _novoQtdeBase = Number(_auxQtdeBase) + Number(_qtdeBase);
                  _newReg.qtdeBase = Number(_novoQtdeBase).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 });

                  const _auxMetaMix = convertToNumber(_aux.metaMix);
                  const _metaMix = convertToNumber(_item.metaMix);
                  const _novoMetaMix = Number(_auxMetaMix) + Number(_metaMix);
                  _newReg.metaMix = Number(_novoMetaMix).toLocaleString("pt-BR", { minimumFractionDigits: 0, maximumFractionDigits: 0 });

                  const _auxMix = convertToNumber(_aux.mix);
                  const _mix = convertToNumber(_item.mix);
                  const _novoMix = Number(_auxMix) + Number(_mix);
                  _newReg.mix = Number(_novoMix).toLocaleString("pt-BR", { minimumFractionDigits: 0, maximumFractionDigits: 0 });
                  _newReg.percentMix = Number(_novoMetaMix ? (_novoMix / _novoMetaMix) * 100 : 0).toLocaleString("pt-BR", { minimumIntegerDigits: 2, minimumFractionDigits: 2, maximumFractionDigits: 2 });

                  _newReg.metaFaturamento = Number(convertToNumber(_aux.metaFaturamento) + convertToNumber(_item.metaFaturamento)).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                  _newReg.faturamento = Number(convertToNumber(_aux.faturamento) + convertToNumber(_item.faturamento)).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                  _newReg.percentFaturmento = Number(convertToNumber(_aux.metaFaturamento) > 0 ? (convertToNumber(_aux.faturamento) / convertToNumber(_aux.metaFaturamento)) * 100 : 0).toLocaleString("pt-BR", { minimumIntegerDigits: 2, minimumFractionDigits: 2, maximumFractionDigits: 2 });

                  if (_item.codigoFornec === _item.codFornecPrinc &&
                    _aux.codigoFornec !== _aux.codFornecPrinc) {

                    _newReg.codigoFornec = _item.codigoFornec;
                    _newReg.documentFornec = _item.documentFornec;
                    _newReg.nomeFornec = _item.nomeFornec;
                  }

                  _arrayResume[_index] = _newReg;

                }
                else {
                  _arrayResume.push(_item);
                }
              }
            }
          }

          regReturn.list = _arrayReg;
          regReturn.listResume = _arrayResume;

          resolve(regReturn);
        })
        .catch(error => {
          console.log(error);
          let msgerror = error.stack ? error.stack : 'Ocorreu um erro!';
          if (error.response && error.response.data) {
            msgerror = error.response.data.msgErr;
          }
          if (error.response && error.response.result) {
            msgerror = error.response.result.msgErr;
          }

          regReturn.status = '400';
          regReturn.message = msgerror;
          regReturn.msgErro = JSON.stringify(error);
          reject(regReturn)
        });
    }
    catch (err) {
      console.log('getTargetSupplierAssociateService.Service.getAll.err');
      console.log(err);

      let _msgError = 'Ocorreu um erro!';
      regReturn.status = '400';
      regReturn.message = _msgError;
      regReturn.msgErro = JSON.stringify(err);
      reject(regReturn)
    }
  });
}