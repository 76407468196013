import Api from 'Shared/Services/Api/index';
import GetSystemAuthUtil from 'Shared/Utils/ConfigEnvironment/GetSystemAuth/index';

import { ITargetsProduct, ITargetsProductFilters, ITargetsProductResponse } from '../../Interface/index';

export const getTargetsIsMeta = async (params: ITargetsProductFilters) => {
  return new Promise<ITargetsProductResponse>(async (resolve, reject) => {
    let _arrayReg: [ITargetsProduct?] = [];
    let regReturn: ITargetsProductResponse = {
      status: '200',
      message: '',
    };

    try {
      let _url = `/produtometacountismeta`;
      let _config: any = {
        "systemauth": GetSystemAuthUtil(process.env.REACT_APP_ENVIRONMENT)
      };

      if (params.codProduto) {
        _config.codProduto = Number(params.codProduto).toString()
      }
      if (params.nomeProduto) {
        _config.nomeProduto = params.nomeProduto
      }
      if (params.ean) {
        _config.ean = params.ean
      }
      if (params.codigo) {
        _config.codigo = Number(params.codigo).toString()
      }
      if (params.nome) {
        _config.nome = params.nome
      }
      if (params.documento) {
        _config.documento = params.documento
      }
      if (params.codClientePrinc) {
        _config.codClientePrinc = Number(params.codClientePrinc).toString()
      }
      if (params.codigoFornec) {
        _config.codigoFornec = Number(params.codigoFornec).toString()
      }
      if (params.documentFornec) {
        _config.documentFornec = params.documentFornec
      }
      if (params.nomeFornec) {
        _config.nomeFornec = params.nomeFornec
      }
      if (params.codFornecPrinc) {
        _config.codFornecPrinc = Number(params.codFornecPrinc).toString()
      }

      if (params.agrupar) {
        _config.agrupar = Number(params.agrupar).toString();
      }

      await Api.get(_url, { params: _config })
        .then(resp => {
          if (resp.data.data && resp.data.data.length > 0) {
            for (let reg of resp.data.data) {
              const _item: ITargetsProduct = {
                id: reg.guidcontrol ? reg.guidcontrol.value : '',
                codProduto: reg.codProduto ? reg.codProduto.value.padStart(6, '0') : '000000',
                nomeProduto: reg.nomeProduto ? reg.nomeProduto.value : '',
                ean: reg.ean ? reg.ean.value : '',
                codProdutoPrinc: reg.codProdutoPrinc ? reg.codProdutoPrinc.value.padStart(6, '0') : '000000',
                isMeta: reg.isMeta && reg.isMeta.value === 1 ? true : false,

                codigo: reg.codigo ? reg.codigo.value.padStart(4, '0') : '0000',
                nome: reg.nome ? reg.nome.value : '',
                documento: reg.documento ? reg.documento.value : '',

                codigoFornec: reg.codigoFornec ? reg.codigoFornec.value.padStart(6, '0') : '000000',
                documentFornec: reg.documentFornec ? reg.documentFornec.value : '',
                nomeFornec: reg.nomeFornec ? reg.nomeFornec.value : '',

                qtCxFaturada: reg.qtCxFaturada ? Number(reg.qtCxFaturada.value).toLocaleString("pt-BR", { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : '0',
                faturamento: reg.faturamento ? Number(reg.faturamento.value).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0,00',
              }

              _arrayReg.push(_item)
            }
          }

          regReturn.list = _arrayReg;
          regReturn.totRegisters = resp.data && resp.data.totalregistros ? resp.data.totalregistros : 0;
          regReturn.qtdeCxFat = resp.data && resp.data.qtCxFaturada ? resp.data.qtCxFaturada : 0;
          resolve(regReturn);
        })
        .catch(error => {
          console.log(error);
          let msgerror = error.stack ? error.stack : 'Ocorreu um erro!';
          if (error.response && error.response.data) {
            msgerror = error.response.data.msgErr;
          }
          if (error.response && error.response.result) {
            msgerror = error.response.result.msgErr;
          }

          regReturn.status = '400';
          regReturn.message = msgerror;
          regReturn.msgErro = JSON.stringify(error);
          reject(regReturn)
        });
    }
    catch (err) {
      console.log('getTargetsIsMeta.Service.getAll.err');
      console.log(err);

      let _msgError = 'Ocorreu um erro!';
      regReturn.status = '400';
      regReturn.message = _msgError;
      regReturn.msgErro = JSON.stringify(err);
      reject(regReturn)
    }
  });
}