import { IProducts, IProductsResponse } from 'Module/Products/Interface/index';
import Api from 'Shared/Services/Api/index';
import GetSystemAuthUtil from 'Shared/Utils/ConfigEnvironment/GetSystemAuth/index';


export const setProductsService = async (fields: IProducts, id?: string) => {
  return new Promise<IProductsResponse>(async (resolve, reject) => {
    let regReturn: IProductsResponse = {
      status: '200',
      message: '',
    };

    try {
      const _url = id ? `/produto/${id}` : '/produto';

      await Api.post(_url,
        {
          "systemauth": GetSystemAuthUtil(process.env.REACT_APP_ENVIRONMENT),
          "fields": fields
        })
        .then(resp => {
          resolve(regReturn);
        })
        .catch(error => {
          console.log(error);
          let msgerror = error.stack ? error.stack : 'Ocorreu um erro!';
          if (error.response && error.response.data) {
            msgerror = error.response.data.msgErr;
          }
          if (error.response && error.response.result) {
            msgerror = error.response.result.msgErr;
          }

          regReturn.status = '400';
          regReturn.message = JSON.stringify(msgerror);
          regReturn.msgErro = JSON.stringify(error);
          reject(regReturn)
        });
    }
    catch (err) {
      console.log('setProductsService.Service.SetID.err');
      console.log(err);

      let _msgError = 'Ocorreu um erro!';
      regReturn.status = '400';
      regReturn.message = _msgError;
      regReturn.msgErro = JSON.stringify(err);
      reject(regReturn)
    }
  });
}