import React from 'react';
import momenttz from 'moment-timezone';

import { ILayout, ILayoutPage } from 'Module/Layout/Interface';
import SupplierDetailPage from '../Page/index';
import { ISupplierDetailPage } from '../Interface';
import { ISuppliers, ISuppliersContext, ISuppliersResponse } from 'Module/Suppliers/Interface';
import { SUPPLIERS } from 'Shared/Constant/Routes';
import RecordStructureConstant from 'Shared/Constant/Suppliers/RecordStructure';
import { setSuppliersService } from '../Service/SetFornecedor/index';

function SupplierDetailController(props: ILayoutPage) {
  const [contextSuppliers] = React.useState<ISuppliersContext>({ result: [] });
  const [dateUpdateInfo, setDateUpdateInfo] = React.useState<string>(momenttz().tz('America/Sao_Paulo').format());
  const [showLoadingThisPage, setShowLoadingThisPage] = React.useState<boolean>(true);
  const [item, setItem] = React.useState<ISuppliers>({});
  const toggleSetItem = (itemSelected: ISuppliers) => {
    setItem(itemSelected);
  }

  const [showModalQuestionYesNo, setShowModalQuestionYesNo] = React.useState<boolean>(false);
  const toggleShowModalQuestionYesNo = (value: boolean) => {
    setShowModalQuestionYesNo(value);
  }

  const toggleSetSuppliers = React.useCallback(async (suppliers: ISuppliers, id: string) => {
    toggleChangeState('showLoadingThisPage', true);
    await setSuppliersService(suppliers, id)
      .then((response: ISuppliersResponse) => {
        let layoutAlert: ILayout = {
          loading: false,
          typeMessage: 'success',
          titleMessage: 'Salvo',
          message: 'Registro salvo com sucesso',
        }
        props.toggleSetLayout(layoutAlert);
        toggleChangeState('showLoadingThisPage', false);
      })
      .catch((error: ISuppliersResponse) => {
        console.log(error.message);
        let layoutAlert: ILayout = {
          loading: false,
          typeMessage: 'error',
          titleMessage: 'Erro ao salvar',
          message: error.message,
        }
        props.toggleSetLayout(layoutAlert);
        toggleChangeState('showLoadingThisPage', false);
      })
  }, []);//eslint-disable-line

  const toggleChangeState = (field: string, value: any) => {
    switch (field) {
      case 'dateUpdateInfo':
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        break;
      case 'showLoadingThisPage':
        setShowLoadingThisPage(value);
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        break;
      case 'item':
        toggleSetItem(value);
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        break;
      default:
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        break;
    }
  }

  React.useEffect(() => {
    let _item: ISuppliers = {};
    if (props.supplierSelected) {
      _item = props.supplierSelected
    }
    toggleChangeState('item', _item);
    if (!_item.nome) {
      let layoutAlert: ILayout = {
        loading: false,
        typeMessage: 'error',
        titleMessage: 'Não encontrado',
        message: 'Erro ao pesquisar os detalhes do fornecedor. Tente pesquisar novamente',
      }
      props.toggleSetLayout(layoutAlert);
      setTimeout(() => {
        window.location.href = `${SUPPLIERS}#search`;
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.supplierSelected])


  const handlerPage: ISupplierDetailPage = {
    ...props,
    context: contextSuppliers,
    showLoadingThisPage,
    dateUpdateInfo,
    item,
    structure: RecordStructureConstant,
    toggleChangeState,
    toggleSetSuppliers,
    showModalQuestionYesNo,
    toggleShowModalQuestionYesNo,
  }
  return (
    <React.Fragment>
      <SupplierDetailPage {...handlerPage} />

    </React.Fragment>
  )
}


export default SupplierDetailController;
