import momenttz from 'moment-timezone';
// import { data } from '../../Mock/Products/index';
import Api from 'Shared/Services/Api/index';
import GetSystemAuthUtil from 'Shared/Utils/ConfigEnvironment/GetSystemAuth/index';

import { IProducts, IProductsFilters, IProductsResponse } from '../../Interface/index';

const validFilters = (element: IProducts, filters: IProductsFilters) => {
  let isFilter = true
  if (filters.active && filters.active !== 'Todos' && element?.active?.toString() !== filters.active) {
    isFilter = false
  }
  if (filters.codigo && Number(element.codigo) !== Number(filters.codigo)) {
    isFilter = false
  }
  if (filters.ean && element.ean !== filters.ean) {
    isFilter = false
  }
  if (filters.nome && !element.nome?.includes(filters.nome)) {
    isFilter = false
  }
  if (filters.nomeFornec && !element.nomeFornec?.includes(filters.nomeFornec)) {
    isFilter = false
  }
  return isFilter;
}

export const getProductsService = async (params: IProductsFilters) => {
  return new Promise<IProductsResponse>(async (resolve, reject) => {
    let _arrayReg: [IProducts?] = [];
    let regReturn: IProductsResponse = {
      status: '200',
      message: '',
    };

    try {
      let _config = {
        "systemauth": GetSystemAuthUtil(process.env.REACT_APP_ENVIRONMENT)
      };
      await Api.get('/produto', { params: _config })
        .then(resp => {
          if (resp.data.data && resp.data.data.length > 0) {
            for (let reg of resp.data.data) {
              const _item: IProducts = {
                id: reg.guidcontrol.value,
                nome: reg.nome.value,
                codigo: reg.codigo.value ? reg.codigo.value.padStart(6, '0') : '000000',
                ean: reg.ean.value,
                codigoFornec: reg.codigoFornec.value ? reg.codigoFornec.value.padStart(6, '0') : '000000',
                documentFornec: reg.documentFornec.value,
                nomeFornec: reg.nomeFornec.value,
                codFornecPrinc: reg.codFornecPrinc.value ? reg.codFornecPrinc.value.padStart(6, '0') : '000000',
                codProdutoPrinc: reg.codProdutoPrinc.value ? reg.codProdutoPrinc.value.padStart(6, '0') : '000000',
                qtUnitCx: Number(reg.qtUnitCx.value).toLocaleString("pt-BR", { minimumFractionDigits: 0, maximumFractionDigits: 0 }),
                active: reg.active.value === 1 ? true : false,
                criadoEm: momenttz(reg.criadoEm.value).tz('America/Sao_Paulo').format('DD/MM/YYYY HH:mm'),
                alteradoEm: momenttz(reg.alteradoEm.value).tz('America/Sao_Paulo').format('DD/MM/YYYY HH:mm'),
              }

              if (validFilters(_item, params)) {
                _arrayReg.push(_item)
              }

            }
          }
          regReturn.list = _arrayReg;
          resolve(regReturn);
        })
        .catch(error => {
          console.log(error);
          let msgerror = error.stack ? error.stack : 'Ocorreu um erro!';
          if (error.response && error.response.data) {
            msgerror = error.response.data.msgErr;
          }
          if (error.response && error.response.result) {
            msgerror = error.response.result.msgErr;
          }

          regReturn.status = '400';
          regReturn.message = msgerror;
          regReturn.msgErro = JSON.stringify(error);
          reject(regReturn)
        });
    }
    catch (err) {
      console.log('AssociatesReport.Service.getAll.err');
      console.log(err);

      let _msgError = 'Ocorreu um erro!';
      regReturn.status = '400';
      regReturn.message = _msgError;
      regReturn.msgErro = JSON.stringify(err);
      reject(regReturn)
    }
  });
}