import {
  Box, Link, Typography,
} from '@mui/material';
import React from 'react';
import momenttz from 'moment-timezone';

function FooterPage() {
  const [ano, setAno] = React.useState('');

  React.useEffect(() => {
    setAno(momenttz().tz('America/Sao_Paulo').format('yyyy'));
  }, []);

  return (
    <Box id="Footer" className="FooterRoot">
      <Typography component="div" align="justify">
        <span>
          {ano}
        </span>
        <span>&nbsp; Copyright - Todos os direitos reservados &nbsp; </span>
        <Link href='https://promocao.supermercadosredemais.com.br'>
          <span>promocao.supermercadosredemais.com.br</span>
        </Link>
        {/* <Link href={`${window.location.href}`}>
          <span>{`${window.location.hostname}`}</span>
        </Link> */}
      </Typography>
    </Box>
  );
}

export default FooterPage;
