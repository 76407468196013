import React from 'react';
import { Outlet } from 'react-router-dom';

import Footer from 'Shared/Components/Footer/index';
import { ILayout, ILayoutPage } from '../Interface';
import AlertCustom from 'Shared/Components/Alert/index';
import LoadingCustom from 'Shared/Components/Loading/index';
import SlideMenuPage from '../../SideMenu/Controller/index';
import HomeUserPage from '../../HomeUser/Controller/index';
import HomeUserSupplierPage from '../../HomeUserSupplier/Controller/index';
import HeaderPage from 'Shared/Components/Header/index';
import TypeGroupConstant from 'Shared/Constant/TypeGroup/index';
import ModalChangePassword from 'Module/ChangePassword/Controller/index';
import ModalResetPasswordUser from 'Module/ResetPasswordUser/Controller/index';

function LayoutPage(props: ILayoutPage) {
  const [layout, setLayout] = React.useState<ILayout>();
  const toggleLayout = (data: ILayout) => {
    setLayout(data);
  };

  React.useEffect(() => {
    // eslint-disable-next-line react/destructuring-assignment
    toggleLayout(props.layout);
    // eslint-disable-next-line react/destructuring-assignment
  }, [props.layout, props.typeGroup]);

  return (
    <div id="Layout" title="Portal Campanha 2024" data-testid="Portal Campanha 2024">
      {(props?.typeGroup === TypeGroupConstant.ADMIN || props?.typeGroup === TypeGroupConstant.CONSULT) &&
        <SlideMenuPage {...props} />
      }
      {props?.typeGroup === TypeGroupConstant.USER &&
        <HeaderPage {...props} />
      }
      {props?.typeGroup === TypeGroupConstant.SUPPLIER &&
        <HeaderPage {...props} />
      }

      <div id="LayoutRoot" className="LayoutRoot">
        {props?.layout.message
          && <AlertCustom {...props} />}
        {props?.layout.loading
          && <LoadingCustom {...layout} />}
        {props?.typeGroup === TypeGroupConstant.USER &&
          <HomeUserPage {...props} />
        }
        {props?.typeGroup === TypeGroupConstant.SUPPLIER &&
          <HomeUserSupplierPage {...props} />
        }
        {(props?.typeGroup === TypeGroupConstant.ADMIN || props?.typeGroup === TypeGroupConstant.CONSULT) &&
          <Outlet />
        }
      </div>
      {props.showModalChangePassword &&
        <ModalChangePassword {...props} />
      }
      {props.showModalResetPasswordUser &&
        <ModalResetPasswordUser {...props} />
      }
      <Footer />
    </div>
  );
}

export default LayoutPage;
