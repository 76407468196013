import * as React from 'react';
import { Form } from '@unform/web';

import Input from '@mui/material/Input';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { IModalImportFilePage } from './Interface';
import { Table } from 'Shared/Components/Mui/Table/index';
import { Column } from '@material-table/core';

// import InputCustom from 'Shared/Components/Form/InputReactCustom';

function PaperComponent(props: PaperProps) {
  const nodeRef = React.useRef(null);
  return (
    <Draggable
      nodeRef={nodeRef}
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper ref={nodeRef} {...props} />
    </Draggable>
  );
}

export default function ModalImportFilePage(props: IModalImportFilePage) {
  const [open] = React.useState(true);
  const formDefault = React.useRef<any>(null);

  const [msgerror, setMgsError] = React.useState<string>('');

  const [columns, setColumns] = React.useState<Column<object>[]>([]);
  const toggleSetColumns = (values: Column<object>[]) => {
    setColumns(values);
  }

  const [data, setData] = React.useState<object[]>([]);
  const toggleSetData = (values: [any?]) => {
    const _array: object[] = values.map(reg => reg || {});
    setData(_array);
  }

  const handleSubmit = (data: any) => {
    if (props.handleImportFile) {
      props.handleImportFile(data)
    }
  }

  const handleClose = () => {
    if (props.toggleShowModalImportFile) {
      props.toggleShowModalImportFile(false)
    }
  };

  const toggleFilechange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      const fileExtension = selectedFile.name.split('.').pop();
      if (fileExtension === 'xls' || fileExtension === 'xlsx') {
        setMgsError('')
        if (props.toggleFileToImportchange) {
          props.toggleFileToImportchange(event);
        }
      }
      else {
        setMgsError('Arquivo selecionado deve ser do tipo Excel!')
      }
    }
  }

  React.useEffect(() => {
    let _columns: any = [];
    if (props.structFile && props.structFile.columns && props.structFile.columns.length > 0) {
      for (let item of props.structFile.columns) {
        _columns.push(JSON.parse(item))
      }
      toggleSetColumns(_columns);
    }
    else {
      toggleSetColumns(_columns);
    }


    let _rows: any = [];
    if (props.structFile && props.structFile.rows && props.structFile.rows.length > 0) {
      for (let item of props.structFile.rows) {
        _rows.push(JSON.parse(item))
      }
      toggleSetData(_rows);
    }
    else {
      toggleSetData(_rows);
    }


  }, [props.structFile, props.dateUpdateInfoModal])

  return (
    <Box>
      <Dialog
        maxWidth='lg'
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        className='ModalRoot'
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography component={'div'} align='left'>
                <span className='AssociatesReportModalTitle'>{props.title ? props.title : 'Importar arquivo de dados'}</span>
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <DialogContentText component={'div'}>
            <Form
              ref={formDefault}
              id="myFormDefault"
              name="myFormDefault"
              onSubmit={handleSubmit}
            >
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography component="div" align="left" sx={{ padding: '0px 5px 5px 0px' }}>
                    {props.showLoadingUpdateTable && <span>&nbsp;&nbsp;<CircularProgress size={25} /></span>}
                    <label htmlFor="campo-arquivo">Selecione um arquivo:</label><br />
                    {msgerror &&
                      <span className='ModalMessage'>{msgerror}</span>
                    }
                    <Input
                      required
                      fullWidth
                      aria-label='Escolha um arquivo'
                      style={{ marginTop: 10, marginBottom: 10 }}
                      type="file"
                      id="inputfilesCollection"
                      aria-describedby="Importar arquivo Excel"
                      onChange={toggleFilechange} />
                  </Typography>
                </Grid>
                {data && data.length > 0 &&
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Table dataParam={data}
                      columns={columns}
                      title='Relatório'
                      href={''}
                      pageSize={5}
                      toggleSelectItem={() => { }}
                      toggleShowLoadingUpdateTable={props.toggleShowLoadingUpdateTable}
                    />
                  </Grid>
                }
              </Grid>
            </Form>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <Typography component={'div'} align='left'>
                <Button
                  variant="contained"
                  color="success"
                  className="ButtonDanger"
                  type="button"
                  onClick={handleClose}
                >
                  <span>Cancelar</span>
                </Button>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <Typography component={'div'} align='right'>
                <Button
                  variant="contained"
                  color="success"
                  className="ButtonOK"
                  type="submit"
                  onClick={(e) => { formDefault.current.submitForm() }}
                >
                  <span>Importar</span>
                </Button>
              </Typography>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
