import React from 'react';
import momenttz from 'moment-timezone';

import { ILayout, ILayoutPage } from 'Module/Layout/Interface';
import UserNewPage from '../Page/index';
import { IUserNewPage } from '../Interface';
import { IUsers, IUsersContext, IUsersResponse } from 'Module/Users/Interface';
import { USERS } from 'Shared/Constant/Routes';
import RecordStructureConstant from 'Shared/Constant/Users/RecordStructure';
import { setUsersService } from '../Service/SetUser';

function UserNewController(props: ILayoutPage) {
  const [contextUsers] = React.useState<IUsersContext>({ result: [] });
  const [dateUpdateInfo, setDateUpdateInfo] = React.useState<string>(momenttz().tz('America/Sao_Paulo').format());
  const [showLoadingThisPage, setShowLoadingThisPage] = React.useState<boolean>(true);
  const [item, setItem] = React.useState<IUsers>({});
  const toggleSetItem = (itemSelected: IUsers) => {
    setItem(itemSelected);
  }

  const [showModalQuestionYesNo, setShowModalQuestionYesNo] = React.useState<boolean>(false);
  const toggleShowModalQuestionYesNo = (value: boolean) => {
    setShowModalQuestionYesNo(value);
  }

  const [showModalQuestionYesNoDetail, setShowModalQuestionYesNoDetail] = React.useState<boolean>(false);
  const toggleShowModalQuestionYesNoDetail = (value: boolean) => {
    setShowModalQuestionYesNoDetail(value);
  }

  const toggleSetUsers = React.useCallback(async (users: IUsers, id: string) => {
    toggleChangeState('showLoadingThisPage', true);
    await setUsersService(users, id)
      .then((response: IUsersResponse) => {
        let layoutAlert: ILayout = {
          loading: false,
          typeMessage: 'success',
          titleMessage: 'Salvo',
          message: 'Registro salvo com sucesso',
        }
        props.toggleSetLayout(layoutAlert);
        toggleChangeState('showLoadingThisPage', false);
      })
      .catch((error: IUsersResponse) => {
        console.log(error.message);
        let layoutAlert: ILayout = {
          loading: false,
          typeMessage: 'error',
          titleMessage: 'Erro ao salvar',
          message: error.message,
        }
        props.toggleSetLayout(layoutAlert);
        toggleChangeState('showLoadingThisPage', false);
      })
  }, []);//eslint-disable-line

  const toggleChangeState = (field: string, value: any) => {
    switch (field) {
      case 'dateUpdateInfo':
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        break;
      case 'showLoadingThisPage':
        setShowLoadingThisPage(value);
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        break;
      case 'item':
        toggleSetItem(value);
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        break;
      default:
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        break;
    }
  }

  React.useEffect(() => {
    let _item: IUsers = {};
    if (props.userSelected) {
      _item = props.userSelected
    }
    toggleChangeState('item', _item);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.userSelected])



  const handlerPage: IUserNewPage = {
    ...props,
    context: contextUsers,
    showLoadingThisPage,
    dateUpdateInfo,
    item,
    structure: RecordStructureConstant,
    toggleChangeState,
    toggleSetUsers,
    showModalQuestionYesNo,
    toggleShowModalQuestionYesNo,
    showModalQuestionYesNoDetail,
    toggleShowModalQuestionYesNoDetail,
  }
  return (
    <React.Fragment>
      <UserNewPage {...handlerPage} />

    </React.Fragment>
  )
}


export default UserNewController;
